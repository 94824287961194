// useMySelectors.ts
import { useSelector } from "react-redux";


const useMySelectors = () => {

    const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
      (state: any) => ({
        responseOneQuote: state.Quote.responseOneQuote,
        errorOneQuote: state.Quote.errorOneQuote,
        loadingOneQuote: state.Quote.loadingOneQuote,
      }),
    );

  const { loadingUpdateQuote, errorUpdateQuote } = useSelector(
    (state: any) => ({
      loadingUpdateQuote: state.UpdateQuote.loadingUpdateQuote,
      errorUpdateQuote: state.UpdateQuote.errorUpdateQuote,
    }),
  );
  const { responseIdentificationType, errorIdentificationType } = useSelector(
    (state: any) => ({
      responseIdentificationType:
        state.IdentificationType.responseIdentificationType,
      errorIdentificationType: state.IdentificationType.errorIdentificationType,
    }),
  );

  const { responseCatalogGender, errorCatalogGender } = useSelector(
    (state: any) => ({
      responseCatalogGender: state.Plan.responseCatalogGender,
      errorCatalogGender: state.Plan.errorCatalogGender,
    }),
  );

  const { responseCountries, errorCountries } = useSelector((state: any) => ({
    responseCountries: state.Countries.responseCountries,
    errorCountries: state.Countries.errorCountries,
  }));

  const { responseRegion, errorRegion } = useSelector((state: any) => ({
    responseRegion: state.Region.responseRegion,
    errorRegion: state.Region.errorRegion,
  }));

  const { responsePlans, errorPlans, loadingPlans } = useSelector(
    (state: any) => ({
      responsePlans: state.Plans.responsePlans?.data,
      errorPlans: state.Plans.errorPlans,
      loadingPlans: state.Plans.loadingPlans,
    }),
  );

  const { responseInsured, errorInsured } = useSelector((state: any) => ({
    responseInsured: state.Insured.responseInsured,
    errorInsured: state.Insured.errorInsured,
  }));

  const { responsePlan, errorPlan, loading } = useSelector((state: any) => ({
    responsePlan: state.Plan.responsePlan,
    errorPlan: state.Plan.errorPlan,
    loading: state.Plan.loadingPlan,
  }));

  const { responseUpdatePerson, errorUpdatePerson, loadingUpdatePerson } =
    useSelector((state: any) => ({
      responseUpdatePerson: state.Person.responseUpdatePerson,
      errorUpdatePerson: state.Person.errorUpdatePerson,
      loadingUpdatePerson: state.Person.loadingUpdatePerson,
    }));

  const { responseBenefit, errorBenefit, loadingBenefit } = useSelector(
    (state: any) => ({
      responseBenefit: state.Beneficiary.responseBenefit,
      errorBenefit: state.Beneficiary.errorBenefit,
      loadingBenefit: state.Beneficiary.loadingBenefit,
    }),
  );

  const { responseBeneficiary, errorBeneficiary, loadingBeneficiary } =
    useSelector((state: any) => ({
      responseBeneficiary: state.Beneficiary.responseBeneficiary,
      errorBeneficiary: state.Beneficiary.errorBeneficiary,
      loadingBeneficiary: state.Beneficiary.loadingBeneficiary,
    }));

  const {
    responsePatchBeneficiary,
    errorPatchBeneficiary,
    loadingPatchBeneficiary,
  } = useSelector((state: any) => ({
    responsePatchBeneficiary: state.Beneficiary.responsePatchBeneficiary,
    errorPatchBeneficiary: state.Beneficiary.errorPatchBeneficiary,
    loadingPatchBeneficiary: state.Beneficiary.loadingPatchBeneficiary,
  }));

  const { responsePostInsured, errorPostInsured, loadingPostInsured } =
    useSelector((state: any) => ({
      responsePostInsured: state.Insured.responsePostInsured,
      errorPostInsured: state.Insured.errorPostInsured,
      loadingPostInsured: state.Insured.loadingPostInsured,
    }));

  const { responsePatchInsured, errorPatchInsured, loadingPatchInsured } =
    useSelector((state: any) => ({
      responsePatchInsured: state.Insured.responsePatchInsured,
      errorPatchInsured: state.Insured.errorPatchInsured,
      loadingPatchInsured: state.Insured.loadingPatchInsured,
    }));

  const {
    responsePageActionFlags,
    errorPageActionFlags,
    loadingPageActionFlags,
  } = useSelector((state: any) => ({
    responsePageActionFlags:
      state.PageActionFlags.responsePageActionFlags?.data,
    errorPageActionFlags: state.PageActionFlags.errorPageActionFlags,
    loadingPageActionFlags: state.PageActionFlags.loadingPageActionFlags,
  }));



  const { responseOneProject, errorOneProject, loadingOneProject } =
    useSelector((state: any) => ({
      responseOneProject: state.Project.responseOneProject?.data,
      errorOneProject: state.Project.errorOneProject,
      loadingOneProject: state.Project.loadingOneProject,
    }));


  return {
    responseOneQuote,
    errorOneQuote,
    loadingOneQuote,
    loadingUpdateQuote,
    errorUpdateQuote,
    responseIdentificationType,
    errorIdentificationType,
    responseCatalogGender,
    errorCatalogGender,
    responsePlan,
    errorPlan,
    loading,
    responseCountries,
    errorCountries,
    responseRegion,
    errorRegion,
    responsePlans,
    errorPlans,
    loadingPlans,
    responseInsured,
    errorInsured,
    responsePostInsured,
    errorPostInsured,
    loadingPostInsured,
    responsePatchInsured,
    errorPatchInsured,
    loadingPatchInsured,
    responseUpdatePerson,
    errorUpdatePerson,
    loadingUpdatePerson,
    responseBenefit,
    errorBenefit,
    loadingBenefit,
    responsePatchBeneficiary,
    errorPatchBeneficiary,
    loadingPatchBeneficiary,
    responseBeneficiary,
    errorBeneficiary,
    loadingBeneficiary,
    responsePageActionFlags,
    errorPageActionFlags,
    loadingPageActionFlags,
    responseOneProject,
    errorOneProject,
    loadingOneProject,
  };
};

export default useMySelectors;
