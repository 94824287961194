import {
    LoadingAFY,
    position,
    typeAlert,
    typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";
export const ConfirmationHeader = (props: any) => {
    return (
        <>
            <LoadingAFY
                loading={props.loading}
                type={typeLoaders.FolderAnime}
                color={themeCore.colors.third}
                textColor={themeCore.colors.third}
                text={props.text}
                bgColor={themeCore.colors.backgroundLoader}
            />
            {props.seeToast.show &&
                props.showToast(position.topRight, props.seeToast.type, props.seeToast.message, () => {
                    props.setSeeToast({ show: false, message: "", type: typeAlert.success });
                })}
            <HeaderTitlePage
                breadCrumbs={props.breadCrumbs}
                steps={props.steps}
                hideStep={false}
                completed={props.completed}
            />
        </>
    )
}