import React, { Suspense, useEffect, useState } from "react";
import { useStyles } from "./style";
import "./style.css"; // @ts-ignore
import {
  breadcrumb,
  DataFormClient,
  icons,
  itemPlanProps,
  MenuItem,
  select,
  sizesIcon,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ConfirmationHeader } from "./header";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { themeCore } from "../../assets/themes/theme";
import * as catalogConst from "../../const/catalog";
import {
  STEP_CONFIRMATIONFORM,
  STEP_PAYMENTCONFIRMATION,
} from "../../const/catalog";
import { formatPhoneNumber } from "../../utils/formats";
import { navigateNext, stepProgress } from "../../dataBase/stepProgress";
import { Customer } from "../../enum/customer";
import { Level } from "../../enum/region";
import { Steps } from "../../enum/steps";
import { PostUploadDocuments } from "../../fnx/apaAuditDocuments/quoteUploadDocuments";
import { GetCatalogBeneficiary } from "../../fnx/catalog/getCatalogBeneficiary";
import { GetCatalogInsurance } from "../../fnx/catalog/getCatalogInsurance";
import { useCertificateQuote } from "../../fnx/certificate/certificateQuotes";
import { getCustomerAction, isCustomer } from "../../fnx/customer";
import { useGetPermissionList } from "../../fnx/getPermissions";
import { GetPersonByDocument } from "../../fnx/person/getPersonByDocument";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { showErrorToast } from "../../fnx/showError";
import { ActionFlagsModel } from "../../model/actionFlags";
import {
  TableAdditionalPaymentInfo,
  TableBeneInsured,
} from "../../model/beneficiary";
import { Countries } from "../../model/countries";
import { IdentificationType } from "../../model/identificationType";
import { PlanModel } from "../../model/plan";
import { Region } from "../../model/region";
import {
  formatDocument,
  transformToSelect,
} from "../../services/functions";
import { hasPermission } from "../../services/getNavigationPages";
import LocalStorageService from "../../services/localStorage";
import showToast from "../../services/toast";
import { postQuoteUploadDocumentClearData } from "../../store/apapUploadAttachments/actions";
import {
  benefitClearData,
  patchBeneficiaryClearData,
} from "../../store/beneficiary/actions";
import { certificateQuoteClearData } from "../../store/certificates/actions";
import { countries } from "../../store/countries/actions";
import { quoteClearData, quoteOne } from "../../store/generateQuote/actions";
import { identificationType } from "../../store/identificationType/actions";
import {
  patchInsuredClearData,
  postInsuredClearData,
} from "../../store/insured/actions";
import {
  pageActionFlagsClearData,
} from "../../store/pageActionFlags/actions";
import {
  personByDocumentClearData,
} from "../../store/person/actions";
import { catalogGender } from "../../store/plan/actions";
import { plans } from "../../store/plans/actions";
import { projectOne, projectOneClearData } from "../../store/project/actions";
import { region } from "../../store/region/actions";
import {
  formattedDate,
  newFormattedDate,
} from "../../utils/date";
import {
  formatNumberCustom,
  formatToTwoDecimals,
  parsePrice,
} from "../../utils/getPrice";
import {
  AdditionalDocumentationColumns,
  dataEditVehicleForm,
  getFilters,
  rowsClientDocument,
  StatementColumns,
  Tab1Columns,
  Tab2Columns,
  Tab3Columns,
  TabAutoColumns,
  vehicleDetail,
} from "./resources/confirmationFormDto";
import { ConfirmationFormView } from "./view";

/* Beneficiary */
import useBeneficiary from "./components/beneficiary";
/* Insurance */
import useInsurance from "./components/insurance";
/* Vehicle */
import useVehicle from "./components/vehicle";
/* ConfirmationForm */
import useClient from "./components/client";
/* Form Actions */
import useActions from "./components/ui";
/* Selectors */
import useSelectors from "./components/useSelectors";
/* Functions */
import useFunctions from "./components/functions";

const STEP = STEP_CONFIRMATIONFORM;
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
const storageKey3 = "afy-action-flags";
const lsPageActionFlags = new LocalStorageService(storageKey3);
const storageKey4 = "afy-steps";
const lsSteps = new LocalStorageService(storageKey4);

const ConfirmationForm = () => {
  const permissions = {
    create: hasPermission("sale-confirmation-form-create"),
  };
  const {
    updateVehicle,
  } = useVehicle();
  const {
    searchPerson,
    handleSearch,
    callDispatchPlan,
    handleOnChangePaymentMethod,
    handleOnChangeCoverType,
    handleOnChangeCampaign,
    handleChangeSelect,
    handleUpdatePerson,
    handleDownload,
    updateDataFormPerson,
    setCoverTypeStorybook,
    handleOnClickAcquire,
    quoteUploadDocuments,
    callQuoteOne,
    getActionFlags,
    handleOnClickAutoDownload,
    handleOnClickAutoDownloadVida,
    setLocalStorage,
    callDispatchPageActionFlags
  } = useClient();
  const {
    handleAddInsured,
    setInsuranceStorybook,
    handleSubmitInsured,
    editInsured,
    searchInsurancePerson
  } = useInsurance();
  const {
    setBeneficiaryStorybook,
    handleAddBeneficiary,
    handleSubmitBeneficiary,
    getInsuredBeneficiary,
    editBeneficiary
  } = useBeneficiary();

  const {
    handleNext,
    handlePrevious,
    onClickBack,
    handleCloseBackModal,
    handleBackFromModal,
    handleClickContextMenu,
    handleSuccess
  } = useActions();

  const {
    filterData,
    getFormattedPrice,
    mapDataToSelect,
    filterAndSetData
  } = useFunctions();

  const {
    loadingUpdateQuote,
    errorUpdateQuote,
    responseIdentificationType,
    errorIdentificationType,
    responseCatalogGender,
    errorCatalogGender,
    responsePlan,
    errorPlan,
    loading,
    responseCountries,
    errorCountries,
    responseRegion,
    errorRegion,
    responsePlans,
    errorPlans,
    loadingPlans,
    responseInsured,
    errorInsured,
    responsePostInsured,
    errorPostInsured,
    loadingPostInsured,
    responsePatchInsured,
    errorPatchInsured,
    loadingPatchInsured,
    responseUpdatePerson,
    errorUpdatePerson,
    loadingUpdatePerson,
    responseBenefit,
    errorBenefit,
    loadingBenefit,
    responsePatchBeneficiary,
    errorPatchBeneficiary,
    loadingPatchBeneficiary,
    responseBeneficiary,
    errorBeneficiary,
    loadingBeneficiary,
    responsePageActionFlags,
    responseOneProject,
    loadingOneProject,
    errorOneQuote,
    responseOneQuote,
    loadingOneQuote,
  } = useSelectors()


  const { t } = useTranslation();
  const classes = useStyles();
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const [triggerPermissionList, setTriggerPermissionList] =
    React.useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [actionFlags, setActionFlags] = React.useState<ActionFlagsModel[]>(
    lsPageActionFlags.getPageActionFlagsStored(),
  );

  const [planId, setPlanId] = useState<string>("");
  // const [filtersDTO, setFiltersDTO] = useState({});
  const [openDrawerUpload, setOpenDrawerUpload] =
    React.useState<boolean>(false);
  const [idDocumentClient, setIdDocumentClient] = React.useState<string>("");
  const [coverTypeId, setCoverTypeId] = useState<string>("");
  const [campaignId, setCampaignId] = useState<string>("");
  const [catalogPaymentMode, setCatalogPaymentMode] = useState<string>("");
  const [catalogStatusQuote, setCatalogStatusQuote] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [userId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [uploadTrigger, setUploadTrigger] = React.useState<boolean>(false);
  const isVidaTermino = isCustomer(Customer.VIDA);
  const [period, setPeriod] = React.useState<string>("");
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [showInsured, setShowInsured] = React.useState<boolean>(true);
  const [showBeneficiary, setShowBeneficiary] = React.useState<boolean>(true);
  const [addInsured, setAddInsured] = React.useState<boolean>(true);
  const [addBeneficiary, setAddBeneficiary] = React.useState<boolean>(false);
  const [showComponent, setShowComponent] = React.useState<boolean>(false);
  const [openModalBackConfirm, setOpenModalBackConfirm] =
    useState<boolean>(false);
  const [canEdit, setCanEdit] = React.useState<boolean>(true);
  const [certificates, setCertificates] = React.useState<any[]>([]);
  const [rows, setRows] = useState<TableBeneInsured[]>([]);
  const [rows2, setRows2] = useState<TableAdditionalPaymentInfo[]>([]);
  const [rowsBeneficiary, setRowsBeneficiary] = useState<TableBeneInsured[]>(
    [],
  );
  const [hideMarriedName, setHideMarriedName] = React.useState<boolean>(false);
  const [namePlan, setNamePlan] = React.useState<string>("");
  const [isMaskedDocument, setIsMaskedDocument] = useState<boolean>(false);
  const [personQuoteId, setPersonQuoteId] = useState<string>("");
  const [prime, setPrime] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [insuranceType] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].name
      ? localStorageService
        .getStepStored()
        .steps[Steps.insurance].name.toLowerCase()
      : "",
  );
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [listIdentificationType, setListIdentificationType] = React.useState<
    select[]
  >([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const { id } = useParams();
  const { isQuote } = useParams();
  const { isPlan } = useParams();
  const { idProject } = useParams();
  const { isDocumentClient } = useParams();
  const [projectTrigger, setProjectTrigger] = React.useState(false);
  const [triggerCatalog, setTriggerCatalog] = React.useState<boolean>(false);
  const [triggerCertificate, setTriggerCertificate] =
    React.useState<boolean>(false);
  const [listGenders, setListGenders] = useState<select[]>([]);
  const [isQuoteType, setIsQuoteType] = useState<boolean>(isQuote === "true");
  const [canEditBeneficiary, setCanEditBeneficiary] = useState<boolean>(false);
  const [canEditInsured, setCanEditInsured] = useState<boolean>(false);
  const [listCountries, setListCountries] = useState<select[]>([]);
  const [listRegionLevel1, setListRegionLevel1] = useState<select[]>([]);
  const [listRegionLevel2, setListRegionLevel2] = useState<select[]>([]);
  const [listRegionLevel3, setListRegionLevel3] = useState<select[]>([]);
  const [quoteId, setQuoteId] = useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [idQuoteVehicle, setIdQuoteVehicle] = React.useState<string>("");
  const [productId, setProductId] = React.useState<string>("");
  const [limit, setLimit] = React.useState<number>(10000);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalPdfViewer, setopenModalPdfViewer] =
    React.useState<boolean>(false);
  const [urlDocument, setUrlDocument] = React.useState<string>("");
  const [nameDocument, setNameDocument] = React.useState<string>("");
  const [personId, setPersonId] = React.useState<string>("");
  const [openModalBeneficiary, setOpenModalBeneficiary] =
    React.useState<boolean>(false);
  const [paymentMode, setPaymentMode] = React.useState<any>(
    transformToSelect(
      localStorageService.getStepStored().steps[Steps.plan].paymentMode,
    ),
  );
  const [coverageType, setCoverageType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [insuredType, setInsuredType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [beneficiaryType, setBeneficiaryType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [campaign, setCampaign] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].campaign,
  );
  const [planName, setPlanName] = React.useState<string>("");
  const [campaigns, setCampaigns] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].campaign,
  );
  const [campaignSelected, setCampaignSelected] = React.useState<any>({
    id:
      localStorageService.getStepStored().steps[Steps.customer].campaignSelected
        ?.id ?? "",
    name:
      localStorageService.getStepStored().steps[Steps.customer].campaignSelected
        ?.name ?? "",
    value:
      localStorageService.getStepStored().steps[Steps.customer].campaignSelected
        ?.id ?? "",
  });

  const [plan, setPlan] = React.useState<any>({
    id: localStorageService.getStepStored().steps[Steps.customer].id,
    name: localStorageService.getStepStored().steps[Steps.customer].name,
  });
  const [paymentSelected, setPaymentSelected] = React.useState<any>();
  const [planSelected, setPlanSelected] = React.useState<any>();
  const [openModalEditVehicle, setOpenModalEditVehicle] =
    React.useState<boolean>(false);

  const [planData, setPlanData] = React.useState<any>({
    titlePage: "Plan",
    viewType: "2",
    titleMetaTag: "Affinity Plan",
    informationPage: {
      backgroundColor: themeCore.colors.background,
      colorText: themeCore.colors.secondaryText,
      description: "Seleccion el plan que necesite el cliente",
      icon: icons.Help,
      iconSize: sizesIcon.standard,
      iconColor: themeCore.colors.primary,
    },
    filter: {
      backgroundColor: "#FFFFFF",
      bgChipInsurers: "#fff",
      bgChipInsurersSelected: "#EBEEF6",
      bgColorButton: "#fff",
      bgColorComparativeButton: "#3A56A1",
      bgColorIconButton: "#EBEEF6",
      bgColorIconButtonSelected: "#3A56A1",
      colorIconButton: "#3A56A1",
      colorIconButtonSelected: "#fff",
      colorIconChip: "#3A56A1",
      colorText: "#3A56A1",
      comparativeTextButton: "ver comparativa",
      comparativeTextColor: "#fff",
      insurers: [],
      title: "Patrocinador",
      titleFirstChip: "Todas",
    },
    data: [],
  });
  const [campaignInfo, setCampaignInfo] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].campaign,
  );
  const level1: Level = "REGION_LEVEL1";
  const level2: Level = "REGION_LEVEL2";
  const level3: Level = "REGION_LEVEL3";
  const level4: Level = "REGION_LEVEL4";
  const [coverType, setCoverType] = React.useState<select>();
  const [titleInsured, setTitleInsured] =
    useState<string>("Ingresar asegurado");
  const [titleBeneficiary, setTitleBeneficiary] = useState<string>(
    "Ingresar beneficiario",
  );
  const [projectId, setProjectId] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance].id,
  );
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [dataFormVehicle, setDataFormVehicle] =
    React.useState(dataEditVehicleForm);
  const [dataForm, setDataForm] = useState<DataFormClient>({
    DocumentNumber: "",
    QuoteNumber: "",
    QuoteStatusName: "",
    QuoteDate: "",
    Type: "",
    PlanTypeName: "",
    CampaignName: "-",
    IdTypeName: "",
    Name: "",
    SecondName: "",
    FirstLastName: "",
    SecondLastName: "",
    MarriedSurName: "-",
    Gender: "",
    Birthday: "",
    Address1: "-",
    Address2: "-",
    CountryName: "",
    ProvinceName: "",
    TownName: "",
    SectorName: "",
    PostalCode: "",
    PhoneOffice: "",
    PhoneHome: "-",
    PhoneNumber: "-",
    Email: "",
  });
  const [dataFormBeneficiary, setDataFormBeneficiary] = useState<any>({
    Id: "",
    IdType: "",
    idTypeInsured: "",
    Percentage: "",
    DocumentNumber: "",
    Name: "",
    LastNameA: "",
    LastNameB: "",
    marriedName: "",
    Birthday: "",
    PhoneNumber: "",
    IdTypeName: "",
  });
  const [dataFormInsurance, setDataFormInsurance] = useState<any>({
    Id: "",
    IdType: "",
    idTypeInsured: "",
    DocumentNumber: "",
    Name: "",
    LastNameA: "",
    LastNameB: "",
    marriedName: "",
    Birthday: "",
    PhoneNumber: "",
    IdTypeName: "",
  });
  const [initialPercentage, setInitialPercentage] = useState(45);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [document, setDocument] = useState<number>();
  const [isInsurance, setIsInsurance] = useState<boolean>(false);
  const [listVehiclesDetail, setListVehicles] = useState<vehicleDetail[]>([]);
  const [getCampaign, setGetCampaign] = React.useState([]);
  const [salesFlow, setSalesFlow] = React.useState({});
  const [statementRows, setStatementRows] = React.useState<any>([]);
  const [filters, setFilters] = React.useState({});
  const [profileDocumentsRows, setProfileDocumentsRows] =
    React.useState<any>(rowsClientDocument);
  const [countBadge, setCountBadge] = React.useState<number>(0);
  const [carDetails] = React.useState(
    lsSteps.getStepStored().steps[Steps.vehiclesDetails]?.vehiclesDetails,
  );
  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || "",
  );
  const isApapAuto = getCustomerAction().customerAuto.includes(company);
  const hideTabDocumentClient =
    isApapAuto && (isQuote === "true" || isQuote === undefined);
  const isNotQuote = isQuote !== "false";
  const isApapAutoWithoutQuote = isApapAuto && !isNotQuote;
  const [fileName, setFileName] = React.useState<string>("");
  const [pdfFile, setPdfFile] = React.useState<File>();
  const [modalDocumentClient, setModalDocumentClient] =
    React.useState<boolean>(false);
  const [urlDocumentClient, setUrlDocumentClient] = React.useState<string>("");
  const [docName, setDocName] = React.useState<string>("false");
  const [paymentModelSelected, setPaymentModelSelected] =
    React.useState<select>(
      localStorageService.getStepStored().steps[Steps.customer]
        .paymentModeSelected,
    );
  const [coverTypeSelected, setCoverTypeSelected] = React.useState<select>(
    localStorageService.getStepStored().steps[Steps.customer].coverType,
  );
  const [productType, setProductType] = useState<string>("");
  const projectTypeLocal = React.useRef(
    lsSteps.getStepStored().steps[Steps.insurance].flow?.metadata?.navigation
      .productType,
  );
  const { permissionList } = useGetPermissionList(triggerPermissionList);
  const isApap = getCustomerAction().customerAuto.includes(company);
  const [resOneroject, setResOneroject] = React.useState([]);

  
  const {
    responseCertificateQuote,
    errorCertificateQuote,
    loadingCertificateQuote,
  } = useCertificateQuote(triggerCertificate, "cotizacion", quoteId, 1, 20);

  const {
    responseUploadDocuments,
    errorUploadDocuments,
    loadingUploadDocuments,
  } = PostUploadDocuments(
    uploadTrigger,
    id ? id : quoteId,
    fileName,
    pdfFile,
    idDocumentClient,
  );

  const {
    responseGetCatalogBeneficiary,
    errorGetCatalogBeneficiary,
    loadingGetCatalogBeneficiary,
  } = GetCatalogBeneficiary(triggerCatalog, enable, page, limit, clientId);

  const {
    responseGetCatalogInsurance,
    errorGetCatalogInsurance,
    loadingGetCatalogInsurance,
  } = GetCatalogInsurance(triggerCatalog, enable, page, limit, clientId);


  const {
    responsePersonByDocument,
    errorPersonByDocument,
    loadingPersonByDocument,
  } = GetPersonByDocument(trigger, document);

  /* Handle Errors */

  useEffect(() => {
    if (errorPersonByDocument) {
      showErrorToast(errorPersonByDocument, setSeeToast, t);
    }
  }, [errorPersonByDocument]);



  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (errorInsured) {
      showErrorToast(errorInsured, setSeeToast, t);
    }
  }, [errorInsured]);

  useEffect(() => {
    if (errorCatalogGender) {
      showErrorToast(errorCatalogGender, setSeeToast, t);
    }
  }, [errorCatalogGender]);

  useEffect(() => {
    if (errorCountries) {
      showErrorToast(errorCountries, setSeeToast, t);
    }
  }, [errorCountries]);

  useEffect(() => {
    if (errorRegion) {
      showErrorToast(errorRegion, setSeeToast, t);
    }
  }, [errorRegion]);

  useEffect(() => {
    if (errorUpdateQuote) {
      showErrorToast(errorUpdateQuote, setSeeToast, t);
    }
  }, [errorUpdateQuote]);

  useEffect(() => {
    if (errorPlan) {
      showErrorToast(errorPlan, setSeeToast, t);
    }
  }, [errorPlan]);

  useEffect(() => {
    if (errorBeneficiary) {
      showErrorToast(errorBeneficiary, setSeeToast, t);
    }
  }, [errorBeneficiary]);

  useEffect(() => {
    if (errorIdentificationType) {
      showErrorToast(errorIdentificationType, setSeeToast, t);
    }
  }, [errorIdentificationType]);

  useEffect(() => {
    if (errorUpdatePerson) {
      showErrorToast(errorUpdatePerson, setSeeToast, t);
    }
  }, [errorUpdatePerson]);

  useEffect(() => {
    if (errorPatchBeneficiary) {
      showErrorToast(errorPatchBeneficiary, setSeeToast, t);
    }
  }, [errorPatchBeneficiary]);

  useEffect(() => {
    if (errorPatchInsured) {
      showErrorToast(errorPatchInsured, setSeeToast, t);
    }
  }, [errorPatchInsured]);

  useEffect(() => {
    if (errorBenefit) {
      showErrorToast(errorBenefit, setSeeToast, t);
    }
  }, [errorBenefit]);

  useEffect(() => {
    if (errorPostInsured) {
      showErrorToast(errorPostInsured, setSeeToast, t);
      //setOpenModal(false);
    }
  }, [errorPostInsured]);

  useEffect(() => {
    if (errorPlans) {
      showErrorToast(errorPlans, setSeeToast, t);
    }
  }, [errorPlans]);

  useEffect(() => {
    if (errorUploadDocuments) {
      showErrorToast(errorUploadDocuments, setSeeToast, t);
    }
  }, [errorUploadDocuments]);

  useEffect(() => {
    setProjectTrigger(true);
    if (idProject || projectId) {
      dispatch(
        projectOne({
          id: idProject ? idProject : projectId,
        }),
      );
    }
    setTriggerCatalog(true);

    getActionFlags(  actionFlags,
      setShowInsured,
      setAddInsured,
      isQuote,
      setShowBeneficiary,
      setAddBeneficiary,
      setCanEditInsured,
      setCanEditBeneficiary);

    if (!responseIdentificationType) {
      dispatch(
        identificationType({ enable: enable, page: page, limit: limit }),
      );
    }

    // if (isQuote === "false") {
    //   setSteps(stepProgress(STEP_CONFIRMATIONFORM));
    //   setBreadCrumbs([]);
    // } else {
    setSteps(stepProgress(STEP));
    setBreadCrumbs(stepProgress(STEP, true));
    // }

    if (!responseCountries) {
      dispatch(countries({ enable: enable, page: page, limit: limit }));
    }
    if (!responseCatalogGender?.data) {
      dispatch(catalogGender({ enable: enable, page: page, limit: limit }));
    }
    //Get Quote //
    const quoteId =
      localStorageService.getStepStored().steps[Steps.customer].quoteId || "";
    if (id !== undefined) {
      dispatch(quoteClearData());
      setCanEdit(isQuoteType);
      //Get the quote by id
      callQuoteOne(id);
    } else {
      callQuoteOne(quoteId);
    }
    callDispatchPlan(null, null, paymentModelSelected,
      coverTypeSelected,
      paymentMode,
      enable,
      page,
      limit,
      projectId,
      namePlan,
      clientId);

    if (permissionList?.length === 0) {
      setTriggerPermissionList(true);
    }

    if (permissionList?.length === 0) {
      setTriggerPermissionList(true);
    }
  }, []);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      type CombustionType = "GASOLINE" | "DIESEL" | "ELECTRIC" | "GAS";

      const combustionMap: Record<CombustionType, string> = {
        GASOLINE: t(`carDetails.fullType.gasolina`),
        DIESEL: t(`carDetails.fullType.diesel`),
        ELECTRIC: t(`carDetails.fullType.eléctrico`),
        GAS: t(`carDetails.fullType.gas`),
      };

      const combustionType = responseOneQuote.data.contentMap
        ?.combustion as CombustionType;

      const carCombustion = combustionMap[combustionType] || "";

      const price = parsePrice(
        responseOneQuote.data.contentMap?.deductibleTypeId || 0,
      );
      const newPrice = formatNumberCustom(price || 0);

      const car: vehicleDetail = {
        id: 1,
        brand: responseOneQuote.data.contentMap?.brand?.name,
        model: responseOneQuote.data.contentMap?.model?.name,
        fueltype: carCombustion,
        color: responseOneQuote.data.contentMap?.colors?.name,
        price: `RD$ ${newPrice}`,
        cylinders: responseOneQuote.data.contentMap?.cylinders,
        plate: responseOneQuote.data.contentMap?.plate,
      };
      const vehicleDet: vehicleDetail[] = [];
      vehicleDet.push(car);
      setListVehicles(vehicleDet);
    }
  }, [responseOneQuote]);

  

  useEffect(() => {
    if (responseCertificateQuote && responseCertificateQuote.data?.length > 0) {
      const urlC = responseCertificateQuote.data[0]?.url;
      if (urlC) {
        setUrlDocument(urlC);
        setNameDocument(responseCertificateQuote.data[0]?.title);
        setopenModalPdfViewer(true);
      }
      setTriggerCertificate(false);
      dispatch(certificateQuoteClearData());
    } else if (
      responseCertificateQuote &&
      responseCertificateQuote.data?.length === 0
    ) {
      setTriggerCertificate(false);
      showToastSuccessMessage(
        "No se encontro ningun documento adjunto.",
        typeAlert.warning,
        setSeeToast,
      );
      dispatch(certificateQuoteClearData());
    }
  }, [responseCertificateQuote]);

  useEffect(() => {
    if (errorCertificateQuote) {
      showErrorToast(errorCertificateQuote, setSeeToast, t);
    }
  }, [errorCertificateQuote]);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      setPersonQuoteId(responseOneQuote?.data?.personId);
      setQuoteId(responseOneQuote?.data?.id);
      setPlanId(responseOneQuote?.data?.planId?.id);
      setCoverTypeId(responseOneQuote?.data?.coverType?.id);
      setCampaignId(responseOneQuote?.data?.campaignId?.id);
      setCatalogPaymentMode(responseOneQuote?.data?.catalogPaymentMode?.id);
      setCatalogStatusQuote(responseOneQuote?.data?.catalogStatusQuote?.id);
      setBranchId(responseOneQuote?.data?.branch);
      setPlanName(responseOneQuote?.data?.planId?.name);

      const gender = listGenders.find(
        (g) => g.id === responseOneQuote?.data?.catalogGender?.id,
      );
      const HideMarriedName = gender?.name === "Femenino" ? false : true;
      setHideMarriedName(HideMarriedName);

      /*date = formattedDate(date);*/

      //Province
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level1,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );

      //Municipality
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level2,
          parentRegionId: responseOneQuote.data?.municipality?.parentRegionId,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );
      //District
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level3,
          parentRegionId: responseOneQuote.data?.district?.parentRegionId,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );

      let status = responseOneQuote.data?.catalogStatusQuote?.description;

      if (status == "Cancelada") {
        status = "Fecha de cancelación";
      } else if (status == "Emitida") {
        status = "Fecha de emisión";
      } else if (status == "Expirada") {
        status = "Fecha de expiración";
      } else {
        status = "Fecha de cotización";
      }
      let date =
        responseOneQuote?.data?.policyId?.effectiveFrom ||
        responseOneQuote?.data?.creationDate;
      let nextYearDate = undefined;
      let originalDate;

      if (date) {
        let originalDate = new Date(date);
        originalDate.setFullYear(originalDate.getFullYear() + 1);
        nextYearDate = formattedDate(originalDate.toISOString());
        nextYearDate = originalDate.toISOString();
      }

      setDataForm({
        DocumentNumber: formatDocument(
          responseOneQuote && responseOneQuote.data?.numberId,
        ),
        IdType: responseOneQuote.data?.identificationTypeId?.id
          ? responseOneQuote.data?.identificationTypeId?.id
          : "",
        Type:
          responseOneQuote &&
          responseOneQuote.data?.identificationTypeId?.description,
        QuoteNumber: responseOneQuote.data?.policyId
          ? responseOneQuote.data?.policyId.code
          : responseOneQuote && responseOneQuote.data?.code,
        Name: responseOneQuote && responseOneQuote.data?.firstNameA,
        SecondName: responseOneQuote && responseOneQuote.data?.firstNameB,
        FirstLastName: responseOneQuote && responseOneQuote.data?.lastNameA,
        SecondLastName: responseOneQuote && responseOneQuote.data?.lastNameB,
        MarriedSurName: responseOneQuote && responseOneQuote.data?.marriedName,
        Birthday: responseOneQuote && responseOneQuote.data?.dob,
        Address1: responseOneQuote && responseOneQuote.data?.address1,
        Address2: responseOneQuote && responseOneQuote.data?.address2,
        PhoneOffice:
          responseOneQuote && formatPhoneNumber(responseOneQuote.data?.phone2),
        PhoneHome:
          responseOneQuote && formatPhoneNumber(responseOneQuote.data?.phone1),
        PhoneNumber:
          responseOneQuote && formatPhoneNumber(responseOneQuote?.data?.phone3),
        Email: responseOneQuote && responseOneQuote.data?.email,
        QuoteStatusName:
          responseOneQuote &&
          responseOneQuote.data?.catalogStatusQuote?.description,
        QuoteDate: date,
        effectiveDate:
          responseOneQuote && responseOneQuote.data?.policyId
            ? nextYearDate
            : "",
        PlanTypeName: responseOneQuote && responseOneQuote.data?.planId?.name,
        CampaignName:
          responseOneQuote && responseOneQuote.data?.campaignId?.name,
        IdTypeName:
          responseOneQuote &&
          responseOneQuote.data?.identificationTypeId?.description,
        Gender: responseOneQuote && responseOneQuote.data?.catalogGender?.id,
        Country: responseOneQuote && responseOneQuote.data?.nationality?.id,
        CountryName:
          responseOneQuote && responseOneQuote.data?.nationality?.name,
        Province: responseOneQuote && responseOneQuote.data?.province?.id,
        ProvinceName: responseOneQuote && responseOneQuote.data?.province?.name,
        TownName: responseOneQuote && responseOneQuote.data?.municipality?.name,
        Town: responseOneQuote && responseOneQuote.data?.municipality?.id,
        Sector: responseOneQuote && responseOneQuote.data?.district?.id,
        SectorName: responseOneQuote && responseOneQuote.data?.district?.name,
        PostalCode: responseOneQuote && responseOneQuote.data?.zip,
      });

      //setFormVehicle
      setIdQuoteVehicle(responseOneQuote?.data?.contentMap?.idquoteVehicle);
      setProductId(responseOneQuote?.data?.productId?.id);
      let carConditionName;
      switch (responseOneQuote?.data?.contentMap?.condition) {
        case "USED":
          carConditionName = t(`carDetails.carCondition.usado`);
          break;
        case "NEW":
          carConditionName = t(`carDetails.carCondition.nuevo`);
          break;
      }

      if (responseOneQuote?.data?.profileDocuments) {
        const pendingCount = responseOneQuote.data.profileDocuments.filter(
          (doc: any) => doc?.status === "PENDING",
        ).length;
        setCountBadge(pendingCount);
        const dataRows = responseOneQuote?.data?.profileDocuments?.map(
          (item: any) => ({
            id: item.id,
            docType: item.name,
            state: (() => {
              switch (item.status) {
                case "PENDING":
                  return t(`confirmationData.paymentStatuses.PENDING`);
                case "READY":
                  return t(`confirmationData.paymentStatuses.READY`);
              }
            })(),
            date: "",
            user: "",
            documentClientUrl: item.document?.path || "",
          }),
        );
        setProfileDocumentsRows(dataRows);
      }

      let fuelTypeName;
      switch (responseOneQuote?.data?.contentMap?.combustion) {
        case "GAS":
          fuelTypeName = t(`carDetails.fullType.gas`);
          break;
        case "ELECTRIC":
          fuelTypeName = t(`carDetails.fullType.eléctrico`);
          break;
        case "DIESEL":
          fuelTypeName = t(`carDetails.fullType.diesel`);
          break;
        case "GASOLINE":
          fuelTypeName = t(`carDetails.fullType.gasolina`);
          break;
      }
      const countChassis =
        responseOneQuote?.data?.contentMap?.chassisNumber || "";
      const isChassisValid = countChassis?.length !== 17;
      setDataFormVehicle({
        carDetails: [
          {
            brand: {
              id: responseOneQuote?.data?.contentMap?.brand?.id,
              name: responseOneQuote?.data?.contentMap?.brand?.name,
            },
            model: {
              id: responseOneQuote?.data?.contentMap?.model?.id,
              name: responseOneQuote?.data?.contentMap?.model?.name,
            },
            version: {
              id: responseOneQuote?.data?.contentMap?.version?.id,
              name: responseOneQuote?.data?.contentMap?.version?.name,
            },
            class: {
              id: responseOneQuote?.data?.contentMap?.class?.id,
              name: responseOneQuote?.data?.contentMap?.class?.name,
            },
            type: {
              id: responseOneQuote?.data?.contentMap?.type?.id,
              name: responseOneQuote?.data?.contentMap?.type?.name,
            },
            weight: responseOneQuote?.data?.contentMap?.weight,
            tonnage: responseOneQuote?.data?.contentMap?.tonnage,
            passengerCant: responseOneQuote?.data?.contentMap?.passengerCant,
          },
        ],
        year: responseOneQuote?.data?.contentMap?.year,
        carColor: [
          {
            id: responseOneQuote?.data?.contentMap?.colors?.id,
            name: responseOneQuote?.data?.contentMap?.colors?.name,
          },
        ],
        chassisNumber: responseOneQuote?.data?.contentMap?.chassisNumber,
        shortChassis: isChassisValid,
        price: responseOneQuote?.data?.contentMap?.deductibleTypeId,
        numberCylinders: [
          {
            id: responseOneQuote?.data?.contentMap?.cylinders,
            name: responseOneQuote?.data?.contentMap?.cylinders,
          },
        ],
        fuelType: [
          {
            id: responseOneQuote?.data?.contentMap?.combustion,
            name: fuelTypeName,
          },
        ],
        registrationNumber: responseOneQuote?.data?.contentMap?.plate,
        carCondition: [
          {
            id: responseOneQuote?.data?.contentMap?.condition,
            name: carConditionName,
          },
        ],
      });
      //EndFormVehicle

      //setStatementRows
      if (responseOneQuote.data.billing) {
        const dataRows = responseOneQuote?.data?.billing?.map((item: any) => ({
          accountName: item.account,
          amount: `RD$ ${item.amount}`,
          paymentDate: newFormattedDate(item.paymentDate),
          dateExpire: newFormattedDate(item.dateExpire),
          datePaid: newFormattedDate(item.datePaid),
          state: (() => {
            switch (item.statusBilling) {
              case "GENERATED":
                return t(`confirmationData.paymentStatuses.GENERATED`);
              case "EMMITED":
                return t(`confirmationData.paymentStatuses.EMMITED`);
              case "PAID":
                return t(`confirmationData.paymentStatuses.PAID`);
              case "CANCELLED":
                return t(`confirmationData.paymentStatuses.CANCELLED`);
            }
          })(),
        }));
        setStatementRows(dataRows);
      }
      // EndSetStatmentRows

      // if (id) {
      // setPrime(responseOneQuote?.data?.lastPrice?.amount);
      setPrime(
        responseOneQuote?.data?.lastPrice?.amount
          ? formatToTwoDecimals(responseOneQuote.data.lastPrice.amount)
          : "0.00",
      );
      callDispatchPageActionFlags(responseOneQuote?.data?.planId?.projectId,enable, page, limit,clientId);
      if (id) {
        setLocalStorage(responseOneQuote.data, responseOneProject,salesFlow,setCampaignSelected,setCampaign,setCampaigns);

        // setSteps(stepProgress(STEP));
        // setBreadCrumbs(stepProgress(STEP, true));
        if (isQuote === "false") {
          setSteps(stepProgress(STEP_PAYMENTCONFIRMATION));
          setBreadCrumbs([]);
        } else {
          setSteps(stepProgress(STEP));
          setBreadCrumbs(stepProgress(STEP, true));
        }
        let certs = responseOneQuote?.data?.certificates;
        //Get the certificates
        if (certs.length > 0) {
          certs = certs.map((c: any, id: any) => {
            return {
              id: c.id,
              name: c.title,
              url: c.url,
            };
          });
        }
        setCertificates(certs);

        //Get the additional payment info
        if (responseOneQuote?.data && isQuote && isQuote === "false") {
          let additionalInfo: TableAdditionalPaymentInfo[] = [];
          additionalInfo.push({
            id: responseOneQuote?.data?.id!,
            accountName: responseOneQuote?.data?.customerAccount?.accountName,
            accountNumber:
              responseOneQuote?.data?.customerAccount?.accountNumber,
            accountType:
              responseOneQuote?.data?.customerAccount?.accountType?.description,
            paymentMode:
              responseOneQuote?.data?.catalogPaymentMode?.description,
            prime: `${responseOneQuote?.data?.lastPrice?.currencySymbol} ${responseOneQuote?.data?.lastPrice?.amount ?? "0.00"
              }`,
            lastDigits: "N/A",
          });
          setRows2(additionalInfo);
        }
      } else {
        setRows2([
          {
            id: "1",
            accountName: "N/A",
            accountNumber: "N/A",
            accountType: "N/A",
            paymentMode: "N/A",
            prime: "N/A",
            lastDigits: "N/A",
          },
        ]);
      }
      getInsuredBeneficiary(responseOneQuote);

      /*Adiciona al resumen la informacion de vehiculos*/
      switch (responseOneQuote?.data?.contentMap?.condition) {
        case "USED":
          carConditionName = t(`carDetails.carCondition.usado`);
          break;
        case "NEW":
          carConditionName = t(`carDetails.carCondition.nuevo`);
          break;
      }
      setFilters({
        ...filtersDTO,
        prime: responseOneQuote?.data?.lastPrice
          ? formatToTwoDecimals(responseOneQuote?.data?.lastPrice?.amount)
          : "0.00000",
        currency: responseOneQuote?.data?.lastPrice
          ? responseOneQuote?.data?.lastPrice?.currencySymbol
          : "RD$",
        year: responseOneQuote?.data?.contentMap?.year,
        model: responseOneQuote?.data?.contentMap?.model?.name,
        brand: responseOneQuote?.data?.contentMap?.brand?.name,
        chassis: responseOneQuote?.data?.contentMap?.chassisNumber,
        plate: responseOneQuote?.data?.contentMap?.plate,
        statusVehicle: carConditionName,
        product: responseOneQuote?.data?.productId?.description,
        plan: responseOneQuote?.data?.planId?.name,
        campaing: responseOneQuote?.data?.campaignId?.name,
        gender: responseOneQuote?.data?.catalogGender?.description,
        birthdate: responseOneQuote?.data?.dob,
      });
    }
    dispatch(projectOneClearData());
  }, [responseOneQuote]);

  useEffect(() => {
    if (responseOneProject) {
      setSalesFlow(responseOneProject.salesFlow);
      setProductType(
        responseOneProject.salesFlow?.metadata?.navigation?.productType,
      );
    }
  }, [responseOneProject]);

  useEffect(() => {
    if (responseGetCatalogBeneficiary && responseGetCatalogBeneficiary?.data) {
      let beneficiaryList: select[] = [];
      responseGetCatalogBeneficiary.data?.map((item: IdentificationType) => {
        beneficiaryList.push({
          id: item.id,
          name: item.description ? item.description : "",
          value: item.id,
        });
      });
      setBeneficiaryType(beneficiaryList);

      setDataFormBeneficiary({
        ...dataFormBeneficiary,
        idTypeInsured: beneficiaryList[0].value ?? "",
      });
    }
  }, [responseGetCatalogBeneficiary]);

  useEffect(() => {
    if (responseGetCatalogInsurance && responseGetCatalogInsurance?.data) {
      let insuranceList: select[] = [];
      responseGetCatalogInsurance.data?.map((item: IdentificationType) => {
        insuranceList.push({
          id: item.id,
          name: item.description ? item.description : "",
          value: item.id,
        });
      });

      setInsuredType(insuranceList);
      setDataFormInsurance({
        ...dataFormInsurance,
        idTypeInsured: insuranceList[0].value ?? "",
      });
    }
  }, [responseGetCatalogInsurance]);

  useEffect(() => {
    if (responsePlans) {
      console.log("responsePlans",responsePlans);
      const dataST: itemPlanProps[] = [];
      let pm =
        localStorageService.getStepStored().steps[Steps.plan]
          .paymentModeSelected?.name;
      if (period === undefined) {
        setPeriod(pm);
      }
      responsePlans.map((item: PlanModel) => {
        dataST.push({
          id: item.id,
          name: item.name ? item.name.replace(/"/g, "") : "",
          logo: item.sponsorLogoUrl && item.sponsorLogoUrl,
          backgroundColor: "#ffffff",
          currency: "$",
          itemsValue: [],
          option: setCoverTypeStorybook(item.coverTypes ? item.coverTypes : []),
          period: period || pm,
          price: item.price ? item.price?.amount : "0",
          textColor: "#3A56A1",
        });
      });
      setPlanData({ ...plan, data: dataST });
    }
  }, [responsePlans]);

  useEffect(() => {
    if (permissionList?.length > 0) {
      setTriggerPermissionList(false);
    }
  }, [permissionList]);

  useEffect(() => {
    if (
      listIdentificationType.length > 0 &&
      listGenders.length > 0 &&
      listCountries.length > 0 &&
      listRegionLevel1.length > 0 &&
      listRegionLevel2.length > 0 &&
      listRegionLevel3.length > 0 &&
      !showComponent
    ) {
      setShowComponent(true);
    }
  }, [
    listIdentificationType,
    listGenders,
    listCountries,
    listRegionLevel1,
    listRegionLevel2,
    listRegionLevel3,
  ]);

  useEffect(() => {
    if (responsePageActionFlags) {
      lsPageActionFlags.setPageActionFlagsStored(responsePageActionFlags);
      setActionFlags(responsePageActionFlags);
      getActionFlags(actionFlags, setShowInsured, setAddInsured, isQuote, setShowBeneficiary, setAddBeneficiary, setCanEditInsured, setCanEditBeneficiary);
      dispatch(pageActionFlagsClearData());
    } else {
    }
  }, [responsePageActionFlags]);

  useEffect(() => {
    if (responsePatchBeneficiary && responsePatchBeneficiary.data) {
      showToastSuccessMessage(
        t("confirmation.beneficiaryUpdated"),
        typeAlert.success,
        setSeeToast,
      );
      setOpenModal(false);
      // dispatch(person({ enable: true, page: 1, limit: 1000 }));
      dispatch(patchBeneficiaryClearData());
      getInsuredBeneficiary(responseOneQuote);

    }
  }, [responsePatchBeneficiary]);

  useEffect(() => {
    if (responsePatchInsured && responsePatchInsured.data) {
      showToastSuccessMessage(
        t("confirmation.insuredUpdated"),
        typeAlert.success,
        setSeeToast,
      );
      setOpenModal(false);
      // dispatch(person({ enable: true, page: 1, limit: 1000 }));
      dispatch(patchInsuredClearData());
      getInsuredBeneficiary(responseOneQuote);

    }
  }, [responsePatchInsured]);

  useEffect(() => {
    if (responsePostInsured) {
      showToastSuccessMessage(
        t("confirmation.insuredAdded"),
        typeAlert.success,
        setSeeToast,
      );
      setOpenModal(false);
      // dispatch(person({ enable: true, page: 1, limit: 1000 }));
      dispatch(postInsuredClearData());
      getInsuredBeneficiary(responseOneQuote);

      postInsuredClearData();
    }
  }, [responsePostInsured]);

  useEffect(() => {
    if (responseUpdatePerson?.data) {
      localStorageService.setStepStored({
        id: "",
        name: "Confirmation Form",
        step: Steps.confirmData,
        completed: true,
        quoteId: "",
        campaign: "",
      });
      showToastSuccessMessage(
        t("confirmation.added"),
        typeAlert.success,
        setSeeToast,
      );

      if (id !== undefined) {
        dispatch(quoteClearData());
        dispatch(quoteOne({ id: id, fullBody: true }));
      } else {
        dispatch(
          quoteOne({
            id: localStorageService.getStepStored().steps[Steps.customer]
              .quoteId,
            fullBody: true,
          }),
        );
      }
    }
  }, [responseUpdatePerson]);

  useEffect(() => {
    if (responseBenefit && responseBenefit?.data) {
      showToastSuccessMessage(
        t("confirmation.beneficiaryAdded"),
        typeAlert.success,
        setSeeToast,
      );
      setOpenModalBeneficiary(false);
      // dispatch(person({ enable: true, page: 1, limit: 10000 }));
      getInsuredBeneficiary(responseOneQuote);
      dispatch(benefitClearData());
    }
  }, [responseBenefit]);

  useEffect(() => {
    if (responsePlan && responsePlan.data) {
      let result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_PAYMENT_MODE,
      );

      if (result.length > 0) {
        setPaymentModelSelected(paymentMode[0]);
      }
    }
  }, [responsePlan]);

  useEffect(() => {
    if (
      responseIdentificationType &&
      responseIdentificationType.data?.length > 0
    ) {
      let idf: select[] = [];
      responseIdentificationType.data?.map((item: IdentificationType) => {
        idf.push({
          id: item.id,
          name: item.description ? item.description : "",
          value: item.id,
        });
      });
      setListIdentificationType(idf);
      setDataFormBeneficiary({
        ...dataFormBeneficiary,
        IdType: idf[0].value ?? "",
      });
      setDataFormInsurance({
        ...dataFormInsurance,
        IdType: idf[0].value ?? "",
      });
    } else {
      //navigate("/customerForm");
    }
  }, [responseIdentificationType]);

  useEffect(() => {
    if (responseCatalogGender && responseCatalogGender.data?.length > 0) {
      const data: select[] = responseCatalogGender.data?.map(
        (gender: PlanModel) => {
          return {
            id: gender.id,
            value: gender.id,
            name: gender.description,
          };
        },
      );
      setListGenders(data);
    } else {
      //navigate("/customerForm");
    }
  }, [responseCatalogGender]);

  useEffect(() => {
    if (responseCountries && responseCountries.data?.length > 0) {
      const data: select[] = responseCountries.data?.map(
        (country: Countries) => {
          return {
            id: country.id,
            value: country.id,
            name: country.description,
          };
        },
      );
      setListCountries(data);
    } else {
      //navigate("/customerForm");
    }
  }, [responseCountries]);

  useEffect(() => {
    if (responseRegion && responseRegion?.data) {
      const data = responseRegion.data?.map((region: Region) => {
        return {
          id: region.id,
          value: region.id,
          name: region.name,
        };
      });
      data.unshift({ id: "-1", value: "-1", name: " " });
      if (responseRegion.level === level1) {
        setListRegionLevel1(data);
      } else if (responseRegion.level === level2) {
        if (data.length > 0) {
          setListRegionLevel2(data);
        } else {
          setListRegionLevel2([{ id: "-1", value: "-1", name: " " }]);
        }
      } else if (responseRegion.level === level3) {
        if (data.length > 0) {
          setListRegionLevel3(data);
        } else {
          setListRegionLevel3([{ id: "-1", value: "-1", name: " " }]);
        }
      }
    }
  }, [responseRegion]);

  useEffect(() => {
    if (responseBeneficiary?.data) {
      setBeneficiaryStorybook(responseBeneficiary.data);
    }
  }, [responseBeneficiary?.data]);

  useEffect(() => {
    if (responseInsured?.data) {
      setInsuranceStorybook(responseInsured?.data,setRows);
    }
  }, [responseInsured]);

  useEffect(() => {
    if (responsePlan && responsePlan.data) {
      /* Filtrar por payment mode */
      let result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_PAYMENT_MODE,
      );
      if (result.length > 0) {
        setPaymentMode(result);
      }

      /* Filtrar por coverage type */
      result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_BENEFICIARY_TYPE,
      );
      if (result.length > 0) {
        setCoverageType(result);
      }
      /* Filtrar por insured type */
      result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_INSURED_TYPE,
      );
      // if (result.length > 0) {
      //   setInsuredType(result);
      //   setDataFormInsurance({
      //     ...dataFormInsurance,
      //     idTypeInsured: result[0].value ?? "",
      //   });
      // }
      /* Filtrar por beneficiary type */
      result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_BENEFICIARY_TYPE,
      );
      // if (result.length > 0) {
      //   setBeneficiaryType(result);
      //   setDataFormBeneficiary({
      //     ...dataFormBeneficiary,
      //     idTypeInsured: result[0].value ?? "",
      //   });
      // }
    }
  }, [responsePlan]);

  useEffect(() => {
    if (actionFlags.length > 0) {
      getActionFlags(actionFlags, setShowInsured, setAddInsured, isQuote, setShowBeneficiary, setAddBeneficiary, setCanEditInsured, setCanEditBeneficiary);
    }
  }, [actionFlags]);

  useEffect(() => {
    if (responsePersonByDocument && responsePersonByDocument.length > 0) {
      const dataPerson = responsePersonByDocument[0];
      const updatedDataFormPerson = updateDataFormPerson(
        dataPerson,
        isInsurance ? dataFormInsurance : dataFormBeneficiary,
      );
      if (isInsurance) {
        setDataFormInsurance(updatedDataFormPerson);
      } else {
        setDataFormBeneficiary(updatedDataFormPerson);
      }
      setTrigger(false);
      dispatch(personByDocumentClearData());
    }
    if (responsePersonByDocument?.length === 0) {
      if (isInsurance) {
        setDataFormInsurance({
          Name: "",
          SecondName: "",
          LastNameA: "",
          LastNameB: "",
          marriedName: "",
          Birthday: "",
          PhoneNumber: "",
        });
      } else {
        setDataFormBeneficiary({
          Name: "",
          SecondName: "",
          LastNameA: "",
          LastNameB: "",
          marriedName: "",
          Birthday: "",
          PhoneNumber: "",
        });
      }
      dispatch(personByDocumentClearData());
    }
  }, [responsePersonByDocument, isInsurance, trigger]);

  useEffect(() => {
    setUploadTrigger(false);
    setOpenDrawerUpload(false);

    dispatch(postQuoteUploadDocumentClearData());
    if (id !== undefined) {
      dispatch(quoteClearData());
      callQuoteOne(id);
    } else {
      callQuoteOne(quoteId);
    }
  }, [responseUploadDocuments]);

  const columnsInsurance = Tab1Columns((row:any)=>editInsured(row,setIsMaskedDocument,setIsUpdate,setPersonId,setTitleInsured,setOpenModal,listIdentificationType,setDataFormInsurance,dataFormInsurance), canEditInsured);

  const columnsBeneficiary = Tab2Columns((row:any)=>editBeneficiary(row,setIsMaskedDocument,setIsUpdate,setPersonId,setTitleBeneficiary,setOpenModalBeneficiary,setInitialPercentage,listIdentificationType,setDataFormBeneficiary,dataFormBeneficiary,initialPercentage), canEditBeneficiary);

  const filtersDTO = {
    subdomain: lsLogin.getUserLoginStored().subdomain || "",
    isQuote: isQuote || "",
    currency: currency,
    prime: prime,
    steps: localStorageService.getStepStored().steps,
  };


  return (
    <React.Fragment>

      <ConfirmationHeader
        loading={loadingPostInsured || loadingUpdatePerson || loadingOneQuote || loadingBenefit || loadingPostInsured ||
          loadingPatchBeneficiary || loadingPatchInsured || loadingPersonByDocument || loadingOneProject || loadingBeneficiary ||
          loading || loadingPlans || loadingUpdateQuote || loadingUploadDocuments || loadingGetCatalogBeneficiary || loadingGetCatalogInsurance || loadingCertificateQuote}
        text={loadingUpdatePerson || loadingUpdateQuote ? t("confirmationData.loadingMsgV1") : loadingUploadDocuments ? t("confirmationData.loadingMsgV3") : t("confirmationData.loadingMsgV2")}
        seeToast={seeToast}
        showToast={showToast}
        setSeeToast={setSeeToast}
        breadCrumbs={breadCrumbs}
        steps={steps}
        completed={isQuote === "false" ? true : false}


      />

      {showComponent ? (
        <Suspense>
          <ConfirmationFormView
            /* PDF VIEWER DOCUMENT*/
            openModal={openModalPdfViewer}
            setOpenModal={setopenModalPdfViewer}
            documentName={nameDocument}
            urlDocument={urlDocument}
            /* PDF VIEWER CLIENT*/
            openModalClient={modalDocumentClient}
            setOpenModalClient={setModalDocumentClient}
            documentNameClient={docName}
            urlDocumentClient={urlDocumentClient}
            /* MODAL EDIT VEHICLE */
            openModalEditVehicle={openModalEditVehicle}
            setOpenModalEditVehicle={setOpenModalEditVehicle}
            dataFormVehicle={dataFormVehicle}
            callQuoteOneApi={callQuoteOne}
            quoteId={id ? id : quoteId}
            planId={planId}
            coverTypeId={coverTypeId}
            campaignId={campaignId}
            catalogPaymentMode={catalogPaymentMode}
            catalogStatusQuote={catalogStatusQuote}
            branchId={branchId}
            userId={userId}
            idQuoteVehicle={idQuoteVehicle}
            productId={productId}
            t={t}
            planName={planName}
            productType={productType}
            projectTypeLocal={projectTypeLocal}
            salesFlow={salesFlow}
            /* MODAL BENEFICIARY */
            openModalBeneficiary={openModalBeneficiary}
            setOpenModalBeneficiary={setOpenModalBeneficiary}
            isMaskedDocument={isMaskedDocument}
            dataFormBeneficiary={dataFormBeneficiary}
            titleBeneficiary={titleBeneficiary}
            beneficiaryType={beneficiaryType}
            listIdentificationType={listIdentificationType}
            initialPercentage={initialPercentage}
            handleSubmitBeneficiary={() => handleSubmitBeneficiary(dataFormBeneficiary,initialPercentage,responseOneQuote,clientId,isUpdate,setOpenModalBeneficiary)}
            searchPerson={(e: any) => searchPerson(e,setIsInsurance,setDocument,setTrigger)}
            /* MODAL INSURED */
            openModalInsured={openModal}
            setOpenModalInsured={setOpenModal}
            dataFormInsurance={dataFormInsurance}
            titleInsured={titleInsured}
            insuredType={insuredType}
            handleSubmitInsured={(data:any) => handleSubmitInsured(data,responseOneQuote,clientId,isUpdate,personId,setOpenModal)}
            searchInsurancePerson={(e: any) => searchInsurancePerson(e,handleSearch)}
            /* MODAL CONFIRMATION */
            openModalBackConfirm={openModalBackConfirm}
            handleCloseBackModal={()=>handleCloseBackModal(setOpenModalBackConfirm)}
            handleBackFromModal={()=>handleBackFromModal(navigate)}
            classes={classes}
            /* VIEW */
            isRequired={!isApap}
            tabAdditionalDocumentation={"Documentos Clientes"}
            countBadgeContent={countBadge}
            customBadgeColor={"#fba730"}
            badgeColorText={"#ffffff"}
            additionalDocumentationColumns={AdditionalDocumentationColumns(
              (items, params) => handleClickContextMenu(items, params,setOpenDrawerUpload,setPdfFile,setFileName,setIdDocumentClient,setModalDocumentClient,setUrlDocumentClient,setDocName),
      
            )}
            additionalDocumentationRows={profileDocumentsRows}
            hideMarriedName={hideMarriedName}
            titleDrawer={"Suba el documento solicitado."}
            descriptionDrawer={
              "Arrastra el documento solicitado a la zona de color o haz clic en el área de color para seleccionarlo. Solo puedes subir un documento a la vez, y solo se permiten archivos en formato PDF."
            }
            titleUpload={"Arrastre y suelte su archivo."}
            heightDrawer={"100%"}
            disableSelectCountry={true}
            openDrawerUpload={openDrawerUpload}
            onCloseDrawer={() => {
              setOpenDrawerUpload(false);
            }}
            onUpload={(e: any) => quoteUploadDocuments(e,setFileName,setPdfFile,setUploadTrigger)}
            tabAuto={t("confirmationData.tabAutoTitle")}
            tabAutoDescription={""}
            btnLblDownload={t("confirmationData.btnLblDownload")}
            tabStatement={t(`confirmationData.titleTabStatement`)}
            tabStatementColumns={StatementColumns(t)}
            tabStatementRows={statementRows}
            showButtonDownloadApap={isApapAuto}
            TabAutoColumns={TabAutoColumns(
              (e) => updateVehicle(e, setOpenModalEditVehicle),
              isQuote ? isQuote : "",
            )}
            TabAutoRows={listVehiclesDetail}
            onChangeSelect={(e: any) => handleChangeSelect(e,enable,page,limit,level2,level3,setListRegionLevel3,listGenders,setHideMarriedName)}
            onClickAutoDownload={() => handleOnClickAutoDownload(setTriggerCertificate)}
            onClickAutoDownloadVida={() => handleOnClickAutoDownloadVida(responseOneQuote,setUrlDocument,setopenModalPdfViewer)}
            labelNumberQuote={
              responseOneQuote?.data?.policyId
                ? t("confirmationData.lblNumberQuote")
                : t("confirmationData.lblNumberQuoteV2")
            }
            title={t("confirmationData.lblTitleConfirmation")}
            identificationList={listIdentificationType}
            GenderList={listGenders}
            CountryList={listCountries}
            ProvinceList={listRegionLevel1}
            TownList={listRegionLevel2}
            SectorList={listRegionLevel3}
            dataForm={dataForm}
            hideTabs={[
              showInsured,
              showBeneficiary,
              !(isQuote === "false"),
              !isApapAuto || isVidaTermino,
              true, //!isApapAutoWithoutQuote, ACTIVAR ESTO PARA CUANDO SE CORRIGA ESTADOS DE CUENTA
              hideTabDocumentClient,
            ]}
            hideAddButton={[addInsured, addBeneficiary]}
            showAdd={true}
            showActions={canEdit}
            showDownloads={!canEdit}
            onClickDownload={(obj:any)=>handleDownload(obj)}
            certificates={certificates}
            dateLabel={
              isQuote !== null && isQuote === "false"
                ? t("confirmationData.lblDate")
                : t("confirmationData.lblDateV2")
            }
            downloadsTitle={t("confirmationData.lblDownloadTitle")}
            labelAdd={t("confirmationData.lblAddBtn")}
            tab1={t("confirmationData.titleTab1")}
            tab1Description={t("confirmationData.descriptionTab1")}
            tab2={t("confirmationData.titleTab2")}
            tab2Description={t("confirmationData.descriptionTab2")}
            tab3={t("confirmationData.titleTab3")}
            tab3Description={t("confirmationData.descriptionTab3")}
            Tab1Columns={columnsInsurance}
            Tab1Rows={rows}
            Tab2Columns={columnsBeneficiary}
            Tab2Rows={rowsBeneficiary}
            Tab3Columns={Tab3Columns}
            Tab3Rows={rows2}
            hide2={true}
            options1={paymentMode}
            options2={[]}
            options3={campaignInfo}
            firstSubtitle={t("confirmationData.firstSubtitle")}
            secondSubtitle={t("confirmationData.secondSubtitle")}
            thirdSubtitle={t("confirmationData.thirdSubtitle")}
            fourthSubtitle={t("confirmationData.fourSubtitle")}
            plan={plan}
            plans={planData.data || []}
            paymentMethodData={paymentMode}
            campaignData={campaign}
            showPaymentMethod={true}
            showCampaign={true}
            onClickAdd={()=>handleAddInsured(setTitleInsured, setIsUpdate, setOpenModal, setDataFormInsurance, dataFormInsurance)}
            onClickRowGrid={() => { }}
            onClickAddBeneficiary={() => handleAddBeneficiary(dataFormBeneficiary,setIsUpdate,setInitialPercentage,setTitleBeneficiary,setOpenModalBeneficiary,setDataFormBeneficiary)}
            filters={
              getCustomerAction().customerAuto.includes(company)
                ? getFilters(filters, isVidaTermino, t)
                : getFilters(filtersDTO, false, t)
            }
            titleSelectButton={t("confirmationData.tittleSelectBtn")}
            previousLabel={t("confirmationData.prevLbl")}
            nextLabel={
              !isVidaTermino ? t("confirmationData.nextLbl") : t("finish")
            }
            showPrevious={canEdit}
            nextDisabled={!permissions.create || !canEdit}
            onBack={() => onClickBack(isDocumentClient, id, setOpenModalBackConfirm, isPlan, navigate, navigateNext, STEP)}
            onClickSave={(data: any) => handleUpdatePerson(data,clientId, hideMarriedName,personQuoteId)}
            onNext={() => handleNext(isVidaTermino,navigate, navigateNext, STEP)}
            onClickPrevious={() => handlePrevious(id, setOpenModalBackConfirm, isPlan, navigate, navigateNext, STEP)}
            onBlurInput={() => { }}
            onChangeInput={() => { }}
            onClickCancel={() => { }}
            disableDocumentType={true}
            disableDocumentNumber={true}
            onChangePaymentMethod={(e: any) => handleOnChangePaymentMethod(
              e,paymentMode,setPeriod, setPaymentModelSelected,setPaymentMode, paymentModelSelected,coverTypeSelected,plans,enable,page, limit, projectId,namePlan,clientId
            )}
            onChangeCampaign={(e: any) => handleOnChangeCampaign(e,campaigns,setCampaignSelected)}
            onChangeCoverType={(e: any) => handleOnChangeCoverType(e,setCoverTypeSelected,paymentModelSelected,paymentMode,coverTypeSelected,plans,enable,page,limit,projectId,namePlan,clientId  
            )}
            onClickAcquire={(e: any) => handleOnClickAcquire(e,coverTypeSelected,setCoverType,setPaymentSelected,setPlanSelected,setPlan,campaignSelected,paymentMode,paymentModelSelected,plans,enable,page,limit,projectId,namePlan,clientId)}
            btnLblDownloadVida="Descargar Solicitud"
            showButtonDownloadVida={isVidaTermino}
            disabledDownloadVida={false}
          />
        </Suspense>
      ) : (
        <div></div>
      )}
    </React.Fragment>
  );
};

export default ConfirmationForm;
