import {
    ModalComponentAFY,
    FormBeneficiaryAFY,

} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import { TFunction } from "i18next";

export const ModalBeneficiary = (props: {
    openModalBeneficiary: boolean;
    setOpenModalBeneficiary: (value: boolean) => void;
    isMaskedDocument: boolean;
    dataFormBeneficiary: any;
    titleBeneficiary: string;
    beneficiaryType: any;
    listIdentificationType: any;
    initialPercentage: number;
    handleSubmitBeneficiary: (data: any) => void;
    searchPerson: (value: React.ChangeEvent<HTMLInputElement>) => void;
    t: TFunction;
}) => {

    const {
        openModalBeneficiary,
        setOpenModalBeneficiary,
        isMaskedDocument,
        dataFormBeneficiary,
        titleBeneficiary,
        beneficiaryType,
        listIdentificationType,
        initialPercentage,
        handleSubmitBeneficiary,
        searchPerson,
        t,
    } = props;

    return (
        <ModalComponentAFY
            openModal={openModalBeneficiary}
            backgroundColor="#ffffff"
            onClose={() => setOpenModalBeneficiary(false)}
            border={0}
            modalWidth={1170}
            padding={15}
            modalHeight={"90%"}
        >
            <FormBeneficiaryAFY
                isntMaskedDocument={isMaskedDocument}
                dataForm={dataFormBeneficiary}
                initialPercentageBeneficiaries={initialPercentage}
                labelPercentage={t("confirmationData.lblPorcentaje")}
                onClickCancel={() => setOpenModalBeneficiary(false)}
                labelInsuranceOrBeneficiary={t(
                    "confirmationData.lblInsuranceBeneficiary",
                )}
                onClickSave={function noRefCheck() { }}
                onNext={handleSubmitBeneficiary}
                openModal
                stepPercentageBeneficiaries={5}
                subtitle={t("confirmationData.subtitle")}
                title={titleBeneficiary}
                typeInsuredOption={beneficiaryType}
                TypeList={listIdentificationType}
                formsCompleted={[true, false, false]}
                showPercentage={true}
                onBlurDocument={(document) => {
                    searchPerson(document);
                }}
                onBlurInput={(document) => {
                    searchPerson(document);
                }}
            />
        </ModalComponentAFY>
    )
};