import {
  ModalComponentAFY,
  FormBeneficiaryAFY,

} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { TFunction } from "i18next";

export const ModalInsurance = (props: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  isMaskedDocument: boolean;
  dataFormInsurance: any;
  titleInsured: string;
  insuredType: any;
  listIdentificationType: any;
  handleSubmitInsured: (data:any) => void;
  searchInsurancePerson: (value: React.ChangeEvent<HTMLInputElement>) => void;
  t: TFunction;

}) => {

  const {
    openModal,
    setOpenModal,
    isMaskedDocument,
    dataFormInsurance,
    titleInsured,
    insuredType,
    listIdentificationType,
    handleSubmitInsured,
    searchInsurancePerson,
    t,
  } = props;

  return (

    <ModalComponentAFY
      openModal={openModal}
      backgroundColor="#ffffff"
      onClose={() => setOpenModal(false)}
      border={0}
      modalWidth={986}
      padding={40}
      modalHeight={"90%"}
    >
      <FormBeneficiaryAFY
        isntMaskedDocument={isMaskedDocument}
        dataForm={dataFormInsurance}
        onClickAcquire={function noRefCheck() { }}
        onClickCancel={() => setOpenModal(false)}
        onClickSave={function noRefCheck() { }}
        onNext={handleSubmitInsured}
        openModal
        subtitle={t("confirmationData.subtitleBeneficiary")}
        title={titleInsured}
        typeInsuredOption={insuredType}
        TypeList={listIdentificationType}
        formsCompleted={[true, false, false]}
        onBlurDocument={(event) => {
          searchInsurancePerson(event);
        }}
        onBlurInput={(event) => {
          searchInsurancePerson(event);
        }}
      />
    </ModalComponentAFY>
  )
};