import React from "react";
import {TFunction} from "i18next";
import {themeCore} from "../../../assets/themes/theme";
import {
  breadcrumb,
  DataFormVehicles,
  DataFormVehiclesNew,
  FormVehicleAPAPAFY,
  icons,
  LoadingAFY,
  MenuItem,
  sizesIcon,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {carConditionOption, filtersData, getFuelTypeOption, getNumberCylindersOption,} from "./vehicleDetailsDTO";
import {HeaderTitlePage} from "../../../components/resources/headerTitlePage/headerTitlePage";

export const VehicleDetailsView = (props: {
  title: string;
  loading: boolean;
  steps: MenuItem[];
  projectType: string;
  breadCrumbs: breadcrumb[];
  searchCarDetails: (e: string) => void;
  searchCarColor: (e: string) => void;
  loadingGetVehicles: boolean;
  loadingCarColor: boolean;
  carDetailsOption: any[];
  carColorOption: any[];
  onNextStep: (data: DataFormVehicles) => void;
  onBackStep: () => void;
  onSelectCarDetails: (value: any) => void;
  onSelectCarColor: (value: any) => void;
  insurance: string;
  planSelected: string;
  formVehicle: DataFormVehiclesNew;
  onBlurChassis?: (data: any) => void;
  onBlurPlate?: (data: any) => void;
  enabledNextBtn?: boolean;
  enabledNextBtnII?: boolean;
  t: TFunction;
}) => {
  return (
    <React.Fragment>
      <LoadingAFY
        loading={props.loading}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={props.t("loading")}
        bgColor={themeCore.colors.backgroundLoader}
      />
      <HeaderTitlePage
        breadCrumbs={props.breadCrumbs}
        titlePage={props.title}
        steps={props.steps}
        hideStep={false}
        stepsTop={"-20px"}
      />
      <FormVehicleAPAPAFY
        abstractTextColor="#666666"
        abstractTitleColor="#3A56A1"
        abstractTitleSize="16px"
        titleRow10=""
        minHeightGrid={"calc(100vh - 145px)"}
        icon={icons.CheckCircle}
        sizesIcon={sizesIcon.smallXl}
        loadingCarDetails={props.loadingGetVehicles}
        loadingCarColor={props.loadingCarColor}
        loadingNumberCylinders={false}
        loadingFuelType={false}
        loadingCarCondition={false}
        formEditView={false}
        prefix={"RD$ "}
        thousandSeparator={","}
        decimalScale={2}
        decimalSeparator={"."}
        maxLengthChassisNumber={17}
        maxLengthRegistrationNumber={7}
        validateGreater={props.projectType === "FULL"}
        isModerVehicle={props.projectType === "FULL"}
        maxYears={1}
        minYears={props.projectType === "LEY" ? 50 : 20}
        filters={filtersData(props.insurance, props.t)}
        abstractTitle={props.t(`carDetails.abstractTitle`)}
        titleForm={props.t(`carDetails.carForm.title`)}
        titleRow1={`*${props.t("carDetails.carForm.titleRow1")}`}
        titleRow2={`*${props.t("carDetails.carForm.titleRow2")}`}
        titleRow3={`*${props.t("carDetails.carForm.titleRow3")}`}
        titleRow4={`*${props.t("carDetails.carForm.titleRow4")}`}
        titleCheckBox={`*${props.t("carDetails.carForm.titleCheckBox")}`}
        titleRow5={`${props.projectType === "FULL" ? "*" : ""}${props.t(
          "carDetails.carForm.titleRow5",
        )}`}
        titleRow6={`*${props.t("carDetails.carForm.titleRow6")}`}
        titleRow7={`*${props.t("carDetails.carForm.titleRow7")}`}
        titleRow8={`*${props.t("carDetails.carForm.titleRow8")}`}
        titleRow9={`*${props.t("carDetails.carForm.titleRow9")}`}
        titleNextButton={props.t(`carDetails.titleNextButton`)}
        titlePreviousButton={props.t(`carDetails.titlePreviousButton`)}
        errorTextCarColor={props.t(`carDetails.errorForm.errorTextCarColor`)}
        errorTextCarCondition={props.t(
          `carDetails.errorForm.errorTextCarCondition`,
        )}
        errorTextCarDetails={props.t(
          `carDetails.errorForm.errorTextCarDetails`,
        )}
        errorTextChassisNumber={props.t(
          props.projectType === "FULL"
            ? `carDetails.errorForm.errorTextChassisNumber`
            : `carDetails.errorForm.errorTextChassisNumberNew`,
        )}
        errorTextFuelType={props.t(`carDetails.errorForm.errorTextFuelType`)}
        errorTextNumberCylinders={props.t(
          `carDetails.errorForm.errorTextNumberCylinders`,
        )}
        errorTextPrice={props.t(`carDetails.errorForm.errorTextPrice`)}
        errorTextRegistrationNumber={props.t(
          `carDetails.errorForm.errorTextRegistrationNumber`,
        )}
        errorTextYear={props.t(
          props.projectType === "FULL"
            ? `carDetails.errorForm.errorTextYear`
            : `carDetails.errorForm.errorTextYearOld`,
        )}
        helperTextCarColor={props.t(
          `carDetails.helperTextForm.helperTextCarColor`,
        )}
        helperTextCarCondition={props.t(
          `carDetails.helperTextForm.helperTextCarCondition`,
        )}
        helperTextCarDetails={props.t(
          `carDetails.helperTextForm.helperTextCarDetails`,
        )}
        helperTextFuelType={props.t(
          `carDetails.helperTextForm.helperTextFuelType`,
        )}
        helperTextNumberCylinders={props.t(
          `carDetails.helperTextForm.helperTextNumberCylinders`,
        )}
        dataForm={props.formVehicle}
        carDetailsOption={props.carDetailsOption}
        fuelTypeOption={getFuelTypeOption(props.t)}
        carConditionOption={carConditionOption(props.t)}
        carColorOption={props.carColorOption}
        numberCylindersOption={getNumberCylindersOption}
        onChangeCarDetails={props.searchCarDetails}
        onSelectCarDetails={props.onSelectCarDetails}
        onChangeCarColor={props.searchCarColor}
        onSelectCarColor={props.onSelectCarColor}
        onChangeCarCondition={function noRefCheck() {}}
        onChangeFuelType={function noRefCheck() {}}
        onChangeNumberCylinders={function noRefCheck() {}}
        onClickBack={props.onBackStep}
        onNext={props.onNextStep}
        onBlurPlate={props.onBlurPlate}
        onBlurChassis={props.onBlurChassis}
        enabledNextBtn={!props.enabledNextBtn && !props.enabledNextBtnII}
      />
    </React.Fragment>
  );
};