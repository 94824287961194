import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCarChassisPlate } from "../../../store/vehiclesApap/actions";
import { carChassisPlateRequest } from "../../../apap/salesCore/vehicleDetail/vehicleDetailsDTO";

export const useGetCarChassisPlate = (
  trigger: boolean,
  body?: carChassisPlateRequest,
) => {
  const dispatch = useDispatch();

  const {
    responseCarChassisPlate,
    errorCarChassisPlate,
    loadingCarChassisPlate,
  } = useSelector((state: any) => ({
    responseCarChassisPlate: state.Vehicles.responseCarChassisPlate,
    errorCarChassisPlate: state.Vehicles.errorCarChassisPlate,
    loadingCarChassisPlate: state.Vehicles.loadingCarChassisPlate,
  }));

  useEffect(() => {
    if (trigger) {
      dispatch(
        getCarChassisPlate({
          body,
        }),
      );
    }
  }, [trigger, body?.data.type]);

  return {
    responseCarChassisPlate,
    errorCarChassisPlate,
    loadingCarChassisPlate,
  };
};