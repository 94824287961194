import React, { useEffect, useState } from "react";
import {
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
  IconButtonAFY,
  icons,
  InformationAFY,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { ClientsView } from "./view";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { person, personByDocumentClearData, personByIdClearData } from "../../store/person/actions";
import { showErrorToast } from "../../fnx/showError";
import { formattedDate } from "../../utils/date";
import {
  Status,
  StatusOptionsModel,
} from "../../model/insurancePoliciesSummary";
import showToast from "../../services/toast";
import { hasPermission } from "../../services/getNavigationPages";

export const ClientsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(5000);
  const [loadingAllQuote] = useState<boolean>(false);
  const [idStatus, setIdStatus] = useState<Status>({});
  const [rows, setRows] = useState<any[]>([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const options = [
    { value: '0', name: 'Documento' },
    { value: '1', name: 'Nombre' },
    { value: '2', name: 'Apellido' },
    { value: '3', name: 'Email' },
  ];
  
  const {
    responsePerson,
    errorPerson,
    loadingPerson,
  } = useSelector((state: any) => ({
    responsePerson:
      state.Person.responsePerson?.data,
    errorPerson: state.Person.errorPerson,
    loadingPerson: state.Person.loadingPerson,
  }));

  useEffect(() => {
      dispatch(
        person({ enable: enable, page: page, limit: limit }),
      );
  }, []);

  useEffect(() => {
    if (responsePerson) {
      

      setRows([]);
      responsePerson.map((item: any) => {
        setRows((prev) => [
          ...prev,
          {
            id: item.id,
            documentId: item.numberId,
            fullName: item.lastNameA + " "+ item.lastNameB +  ", " + item.firstNameA+ " "+ item.firstNameB,
            email: item.email,
            phone: item.phone1,
            dob: item.dob,
          },
        ]);
      });
    }
    
  }, [responsePerson]);

 


const handleClickSeeDetails = (data: any) => {
  dispatch(personByDocumentClearData());
  dispatch(personByIdClearData());
  navigate(`/client-record/${data.documentId}`);
}

const handleSearch = (option: string, term: string) => {
  
  //Document
  if (option === "0") {
    dispatch( person({ enable: enable, page: page, limit: limit, numberId: term }));
  }

  //Name
  if (option === "1") {
    dispatch( person({ enable: enable, page: page, limit: limit, firstNameA: term }));
  }

  //Last Name
  if (option === "2") {
    dispatch( person({ enable: enable, page: page, limit: limit, lastNameA: term }));
  }

  //Email
  if (option === "3") {
    dispatch( person({ enable: enable, page: page, limit: limit, email: term }));
  }

}
  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })  }
      <LoadingAFY
        loading={loadingPerson}
        type={typeLoaders.BarLoader}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text="Cargando clientes"
        bgColor={themeCore.colors.backgroundLoader}
      />
      <ClientsView 
      title={""}
      columns={ 
        [
        {
          field: "actions",
          headerName: "",
          width: 80,
          renderCell: (params) => (
            <div style={{ display: "flex", gap: "10px" }}>
    
              <IconButtonAFY
                bgColorIconButton="#F4F8FA"
                icon={icons.Search}
                iconColor="#006ac6"
                title="Ver detalles"
                onClick={() => handleClickSeeDetails(params.row)}
                disabled={!hasPermission("customer-detail")}
              />
    
    
            </div>
          ),
        },
        {
          field: 'documentId',
          flex: 1,
          headerName: 'Documento',
          width: 150
        },
      
        {
          field: 'fullName',
          flex: 1,
          headerName: 'Nombre completo',
          width: 150
        },
        {
          field: 'email',
          flex: 1,
          headerName: 'Email',
          width: 160
        },
        {
          field: 'phone',
          flex: 1,
          headerName: 'Teléfono',
          width: 150
        },
        {
          field: 'dob',
          flex: 1,
          headerName: 'Fecha Nacimiento',
          width: 150,
          renderCell: (params) => (
          <div>
            {params.value ? formattedDate(params.value) : ""}
           
          </div>
          )
        },
      ]}
      rows={rows}
      onClickSeeDetails={handleClickSeeDetails}
      onSearch={handleSearch}
      listIdentificationType={options}
      
      />
    </React.Fragment>
  );
};
