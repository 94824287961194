import React, { useEffect, useState } from "react";
import {
  breadcrumb,
  DataFormVehicles,
  MenuItem,
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { VehicleDetailsView } from "./vehicleDetailsView";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../../dataBase/stepProgress";
import { STEP_VEHICLEDETAILS } from "../../../const/catalog";
import { useDispatch, useSelector } from "react-redux";
import {
  getCarColor,
  getCarColorClearData,
  getVehicles,
  getVehiclesClearData,
} from "../../../store/vehiclesApap/actions";
import { useNavigate } from "react-router-dom";
import { Steps } from "../../../enum/steps";
import LocalStorageService from "../../../services/localStorage";
import { useGetPlansAll } from "../../../fnx/plans/plansGetAll";
import { useTranslation } from "react-i18next";
import {
  carChassisPlateRequest,
  dataEditVehicleForm,
} from "./vehicleDetailsDTO";
import { useGetCarChassisPlate } from "../../../fnx/apap/vehicles/carChassisPlatePost";
import showToast from "../../../services/toast";
import { showToastSuccessMessage } from "../../../fnx/sendSuccessMessage";

const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);
const STEP = STEP_VEHICLEDETAILS;
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const VehicleDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const projectId =
    localStorageService.getStepStored().steps[Steps.insurance].id;
  const [trigger, setTrigger] = useState(false);
  const [triggerChassisPlate, setTriggerChassisPlate] = useState(false);
  const [enabledNextBtn, setEnabledNextBtn] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [enabledNextBtnII, setEnabledNextBtnII] = useState(false);
  const [pChassisPlateRequest, setPchassisPlateRequest] =
    useState<carChassisPlateRequest>({
      data: {
        type: "VEHICLE",
        fields: [],
      },
    });

  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [carDetailsOption, setCarDetailsOption] = React.useState([]);
  const [carColorOption, setCarColorOption] = React.useState([]);
  const [limitSearchCar] = React.useState<number>(500000000);
  const [limitSearchColor] = React.useState<number>(500000000);
  const [limit] = React.useState<number>(50000000000);
  const [plate, setPlate] = React.useState<string>("");
  const [chassis, setChassis] = React.useState<string>("");
  const [insurance] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance].name,
  );
  const [projectType] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance]?.flow?.metadata
      ?.navigation?.productType,
  );
  const [campaign] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance]?.campaign,
  );
  const [campaignSelected] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance]?.campaignSelected
      .id,
  );
  const [dataCar] = React.useState(
    localStorageService.getStepStored().steps[Steps.vehiclesDetails]
      ?.vehiclesDetails,
  );
  const [planSelected, setPlanSelected] = React.useState<string>("");
  const initializeFormVehicle = () => {
    const storedDataCar =
      localStorageService.getStepStored().steps[Steps.vehiclesDetails]
        ?.vehiclesDetails;
    if (storedDataCar) {
      return {
        carDetails: {
          brand: {
            id: storedDataCar.carDetails?.brand?.id,
            name: storedDataCar.carDetails?.brand?.name,
          },
          model: {
            id: storedDataCar.carDetails?.model?.id,
            name: storedDataCar.carDetails?.model?.name,
          },
          version: {
            id: storedDataCar.carDetails?.version?.id,
            name: storedDataCar.carDetails?.version?.name,
          },
          cylinderCapacity: storedDataCar.carDetails?.cylinderCapacity,
          passengers: storedDataCar.carDetails?.passengers,
          tonnage: storedDataCar.carDetails?.tonnage,
          transmission: storedDataCar.carDetails?.transmission,
          type: storedDataCar.carDetails?.type,
          class: storedDataCar.carDetails?.class,
          weight: storedDataCar.carDetails?.weight,
        },
        year: storedDataCar.year.toString(),
        carColor: {
          id: storedDataCar.carColor?.id,
          name: storedDataCar.carColor?.name,
        },
        chassisNumber: storedDataCar.chassisNumber,
        shortChassis: storedDataCar.shortChassis,
        price: storedDataCar.price,
        numberCylinders: {
          id: storedDataCar.numberCylinders?.id,
          name: storedDataCar.numberCylinders?.name,
        },
        fuelType: {
          id: storedDataCar.fuelType.id,
          name: storedDataCar.fuelType.name,
        },
        registrationNumber: storedDataCar.registrationNumber,
        carCondition: {
          id: storedDataCar.carCondition.id,
          name: storedDataCar.carCondition.name,
        },
      };
    }
    return dataEditVehicleForm;
  };
  const [formVehicle] = React.useState(initializeFormVehicle());

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responsePlans, errorPlans, loadingPlans } = useGetPlansAll(
    trigger,
    clientId,
    projectId,
  );

  /*Llamada al api para validar placas y numero de chasis*/
  const {
    responseCarChassisPlate,
    errorCarChassisPlate,
    loadingCarChassisPlate,
  } = useGetCarChassisPlate(triggerChassisPlate, pChassisPlateRequest);

  const { loadingGetVehicles, responseGetVehicles, errorGetVehicles } =
    useSelector((state: any) => ({
      loadingGetVehicles: state.Vehicles.loadingGetVehicles,
      responseGetVehicles: state.Vehicles.responseGetVehicles?.data,
      errorGetVehicles: state.Vehicles.errorGetVehicles,
    }));

  const { loadingCarColor, responseCarColor, errorCarColor } = useSelector(
    (state: any) => ({
      loadingCarColor: state.Vehicles.loadingCarColor,
      responseCarColor: state.Vehicles.responseCarColor?.data,
      errorCarColor: state.Vehicles.errorCarColor,
    }),
  );

  useEffect(() => {
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    setTrigger(true);
    callGetVehicles("");
    callGetCarColor("", limit);
  }, []);

  useEffect(() => {
    if (responseCarChassisPlate?.length > 0) {
      if (chassis.trim()) {
        setEnabledNextBtn(true);
        showToastSuccessMessage(
          "El chasis ingresado ya existe!",
          typeAlert.warning,
          setSeeToast,
        );
      }
      if (plate.trim()) {
        setEnabledNextBtnII(true);
        showToastSuccessMessage(
          "La placa ingresada ya existe!",
          typeAlert.warning,
          setSeeToast,
        );
      }
    } else {
      if (plate.trim()) {
        setEnabledNextBtnII(false);
      }
      if (chassis.trim()) {
        setEnabledNextBtn(false);
      }
    }

    setTriggerChassisPlate(false);
    // dispatch(getCarChassisPlateClearData());
  }, [responseCarChassisPlate]);

  useEffect(() => {
    if (responseGetVehicles) {
      setCarDetailsOption(responseGetVehicles);
      dispatch(getVehiclesClearData());
    }
  }, [responseGetVehicles]);

  useEffect(() => {
    if (responseCarColor) {
      setCarColorOption(responseCarColor);
      dispatch(getCarColorClearData());
    }
  }, [responseCarColor]);

  useEffect(() => {
    if (responsePlans && responsePlans?.length > 0) {
      const plan = responsePlans[0];
      localStorageService.setStepStored({
        id: plan.id,
        name: plan.name,
        step: Steps.plan,
        completed: false,
        campaign: campaign,
        campaignSelected: campaignSelected,
        coverType: {
          code: "C",
          description: "Titular solamente",
          id: "c24e3622-e228-4549-a814-41043d46339a",
        },
        paymentModeSelected: {
          code: "M",
          description: "Mensual",
          id: "386ba1a4-1a5f-421d-bd93-058f4d478130",
        },
      });
      setPlanSelected(responsePlans[0].name);
    }
  }, [responsePlans]);

  const searchCarDetails = (name: string) => {
    if (name.length === 0) {
      callGetVehicles("");
    }
    if (name.length >= 2) {
      callGetVehicles(name);
    }
  };

  const searchCarColor = (name: string) => {
    if (name.length === 0) {
      callGetCarColor("", limit);
    }
    if (name.length >= 2) {
      callGetCarColor(name, limitSearchColor);
    }
  };

  const callGetVehicles = (brand: string) => {
    dispatch(
      getVehicles({
        brand: brand,
      }),
    );
  };

  const callGetCarColor = (color: string, limitGet: number) => {
    dispatch(
      getCarColor({
        name: color,
        orderBy: "name",
        orderDir: "asc",
        limit: limitGet,
      }),
    );
  };

  const onSelectCarDetails = (value: any) => {
    if (Object.keys(value).length === 0) {
      callGetVehicles("");
    }
  };

  const onSelectCarColor = (value: any) => {
    if (Object.keys(value).length === 0) {
      callGetCarColor("", limit);
    }
  };

  const onBackStep = () => {
    navigateBack(STEP, navigate);
  };

  const onNextStep = (data: DataFormVehicles) => {
    localStorageService.setStepStored({
      id: "",
      name: "",
      step: Steps.vehiclesDetails,
      completed: true,
      vehiclesDetails: data,
    });
    navigateNext(STEP, navigate);
  };

  const onBlurChassis = (data: any) => {
    if (data) {
      setPlate("");
      setChassis(data);
      setPchassisPlateRequest({
        data: {
          type: "VEHICLE",
          fields: [
            {
              name: "chassis_number",
              value: data,
            },
          ],
        },
      });
      setTriggerChassisPlate(true);
    }
  };

  const onBlurPlate = (data: any) => {
    if (data) {
      setChassis("");
      setPlate(data);
      setPchassisPlateRequest({
        data: {
          type: "VEHICLE",
          fields: [
            {
              name: "plate",
              value: data,
            },
          ],
        },
      });
      setTriggerChassisPlate(true);
    }
  };

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <VehicleDetailsView
        title={t(`carDetails.titlePage`)}
        projectType={projectType}
        loading={loading}
        steps={steps}
        breadCrumbs={breadCrumbs}
        searchCarDetails={searchCarDetails}
        searchCarColor={searchCarColor}
        loadingGetVehicles={loadingGetVehicles}
        loadingCarColor={loadingCarColor}
        carDetailsOption={carDetailsOption}
        carColorOption={carColorOption}
        onNextStep={onNextStep}
        onBackStep={onBackStep}
        insurance={insurance}
        t={t}
        onSelectCarDetails={onSelectCarDetails}
        onSelectCarColor={onSelectCarColor}
        planSelected={planSelected}
        formVehicle={formVehicle}
        onBlurChassis={onBlurChassis}
        onBlurPlate={onBlurPlate}
        enabledNextBtn={enabledNextBtn}
        enabledNextBtnII={enabledNextBtnII}
      />
    </>
  );
};