export const GET_VEHICLES = "GET_VEHICLES";
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS";
export const GET_VEHICLES_ERROR = "GET_VEHICLES_ERROR";
export const GET_VEHICLES_CLEAR_DATA = "GET_VEHICLES_CLEAR_DATA";

export const GET_CAR_COLOR = "GET_CAR_COLOR";
export const GET_CAR_COLOR_SUCCESS = "GET_CAR_COLOR_SUCCESS";
export const GET_CAR_COLOR_ERROR = "GET_CAR_COLOR_ERROR";
export const GET_CAR_COLOR_CLEAR_DATA = "GET_CAR_COLOR_CLEAR_DATA";

/*Chasis y Placas*/
export const GET_CAR_CHASSIS_PLATE = "GET_CAR_CHASSIS_PLATE";
export const GET_CAR_CHASSIS_PLATE_SUCCESS = "GET_CAR_CHASSIS_PLATE_SUCCESS";
export const GET_CAR_CHASSIS_PLATE_ERROR = "GET_CAR_CHASSIS_PLATE_ERROR";
export const GET_CAR_CHASSIS_PLATE_CLEAR_DATA =
  "GET_CAR_CHASSIS_PLATE_CLEAR_DATA";