import { useCallback, useMemo } from "react";
import { parsePrice, formatNumberCustom } from "../../../utils/getPrice";
const useFunctions = () => {


    const filterData = useCallback((array: any, key: string, value: string) => {
        const temp = array.filter((e: any) => e[key] === value);
        return temp.map((item: any) => ({
            id: item.id,
            value: item.id,
            name: item.description,
        }));
    }, []); // No dependencies

    // Helper function to parse and format price
    const getFormattedPrice = (priceData: any) => {
        const price = parsePrice(priceData || 0);
        return formatNumberCustom(price || 0);
    };
    const mapDataToSelect = (data: any, idField = 'id', nameField = 'description') => {
        return data.map((item: any) => ({
            id: item[idField],
            value: item[idField],
            name: item[nameField] || '',
        }));
    };
    const filterAndSetData = (data: any, type: any, setState: any) => {
        const result = filterData(data, "type", type);
        if (result.length > 0) {
          setState(result);
        }
      };
    return useMemo(() => ({
        filterData,
        getFormattedPrice,
        mapDataToSelect,
        filterAndSetData

    }), [
        filterData,
        getFormattedPrice,
        mapDataToSelect,
        filterAndSetData

    ]);
};

export default useFunctions;