import {
  GET_CAR_CHASSIS_PLATE,
  GET_CAR_CHASSIS_PLATE_CLEAR_DATA,
  GET_CAR_CHASSIS_PLATE_ERROR,
  GET_CAR_CHASSIS_PLATE_SUCCESS,
  GET_CAR_COLOR,
  GET_CAR_COLOR_CLEAR_DATA,
  GET_CAR_COLOR_ERROR,
  GET_CAR_COLOR_SUCCESS,
  GET_VEHICLES,
  GET_VEHICLES_CLEAR_DATA,
  GET_VEHICLES_ERROR,
  GET_VEHICLES_SUCCESS,
} from "./actionTypes";

export const getVehicles = (vehicle, history) => {
  return {
    type: GET_VEHICLES,
    payload: { vehicle, history },
  };
};

export const getVehiclesSuccess = (success) => {
  return {
    type: GET_VEHICLES_SUCCESS,
    payload: { ...success },
  };
};

export const getVehiclesError = (error) => {
  return {
    type: GET_VEHICLES_ERROR,
    payload: error,
  };
};

export const getVehiclesClearData = () => {
  return {
    type: GET_VEHICLES_CLEAR_DATA,
    payload: {},
  };
};

export const getCarColor = (carColor, history) => {
  return {
    type: GET_CAR_COLOR,
    payload: { carColor, history },
  };
};

export const getCarColorSuccess = (success) => {
  return {
    type: GET_CAR_COLOR_SUCCESS,
    payload: { ...success },
  };
};

export const getCarColorError = (error) => {
  return {
    type: GET_CAR_COLOR_ERROR,
    payload: error,
  };
};

export const getCarColorClearData = () => {
  return {
    type: GET_CAR_COLOR_CLEAR_DATA,
    payload: {},
  };
};

export const getCarChassisPlate = (carChassisPlate, history) => {
  return {
    type: GET_CAR_CHASSIS_PLATE,
    payload: { carChassisPlate, history },
  };
};

export const getCarChassisPlateSuccess = (success) => {
  return {
    type: GET_CAR_CHASSIS_PLATE_SUCCESS,
    payload: success,
  };
};

export const getCarChassisPlateError = (error) => {
  return {
    type: GET_CAR_CHASSIS_PLATE_ERROR,
    payload: error,
  };
};

export const getCarChassisPlateClearData = () => {
  return {
    type: GET_CAR_CHASSIS_PLATE_CLEAR_DATA,
    payload: {},
  };
};