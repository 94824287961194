import {
    ModalComponentAFY,
    ButtonAFY,
    variants,
    sizes
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { TFunction } from "i18next";

export const ModalConfirmation = (props: {
    openModalBackConfirm: boolean;
    handleCloseBackModal: () => void;
    handleBackFromModal: () => void;
    classes: any;
    t: TFunction;
}) => {

    const { openModalBackConfirm, handleCloseBackModal, handleBackFromModal, classes, t } = props;

    return (
        <ModalComponentAFY
            openModal={openModalBackConfirm}
            backgroundColor="#ffffff"
            onClose={handleCloseBackModal}
            border={0}
            modalWidth={500}
            padding={30}
        >
            <div style={{ marginTop: "10px" }}>
                <h2 className={classes.title}>{t("modal.confirmation")}</h2>
                <p className={classes.description}>
                    {t("confirmationData.msgProcessQuestion")}
                </p>
                <div className={classes.buttonsContainer}>
                    <div className={classes.button}>
                        <ButtonAFY
                            variant={variants.contained}
                            size={sizes.large}
                            label={t("confirmationData.btnBoxNot")}
                            onClick={handleCloseBackModal}
                        />
                    </div>
                    <div className={classes.button}>
                        <ButtonAFY
                            variant={variants.contained}
                            size={sizes.large}
                            label={t("confirmationData.btnBoxAfirmative")}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleBackFromModal();
                            }}
                        />
                    </div>
                </div>
            </div>
        </ModalComponentAFY>
    )
};