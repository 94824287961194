import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    select,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import {
    patchInsuredClearData,
    postInsured,
    patchInsured
} from "../../../store/insured/actions";

import {
    TableBeneInsured,
} from "../../../model/beneficiary";
import {
    newFormattedDate,
    newFormattedDateYYMMDD,
} from "../../../utils/date";
import {
    removeNonNumeric,
} from "../../../services/functions";

const useInsurance = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleAddInsured = 
        (setTitleInsured:any, setIsUpdate:any, setOpenModal:any, setDataFormInsurance:any, dataFormInsurance:any) => {
            setTitleInsured(t("confirmationData.titleInsured"));
            setIsUpdate(false);
            setOpenModal(true);
            setDataFormInsurance({
                ...dataFormInsurance,
                Id: "",
                DocumentNumber: "",
                Name: "",
                SecondName: "",
                LastNameA: "",
                LastNameB: "",
                marriedName: "",
                Birthday: "",
                PhoneNumber: "",
                IdTypeName: "",
            });
        }
    

    const setInsuranceStorybook = useCallback(
        (responseInsured: TableBeneInsured[], setRows:any) => {
            const beneficiaryRows = responseInsured?.map((I: TableBeneInsured) => ({
                id: I.id,
                type: I.catalogInsuredTypeInfo?.description ?? "",
                names: `${I.personInfo?.firstNameA} ${I.personInfo?.firstNameB}`,
                lastNames: `${I.personInfo?.lastNameA} ${I.personInfo?.lastNameB}`,
                marriedName: I.personInfo?.marriedName ?? "",
                phone1: I.personInfo?.phone1 ?? "",
                dob: newFormattedDate(I.personInfo?.dob) ?? "",
                documentNumber: I.personInfo?.numberId ?? "",
                idType: I.personInfo?.identificationTypeId ?? "",
                idTypeInsured: I.catalogInsuredTypeInfo?.id ?? "",
            }));
            setRows(beneficiaryRows);
            dispatch(patchInsuredClearData());
        },
        [dispatch]
    );

    const handleSubmitInsured = useCallback(
        (data:any, responseOneQuote:any, clientId:any, isUpdate:any, personId:any, setOpenModal:any) => {
            const body = {
                quoteId: responseOneQuote.data?.id,
                catalogInsuredType: data.idTypeInsured,
                person: {
                    identificationTypeId: data.IdType,
                    firstNameA: data.Name,
                    firstNameB: data.SecondName,
                    numberId: data.DocumentNumber.replace(/-/g, ""),
                    lastNameA: data.LastNameA,
                    lastNameB: data.LastNameB,
                    marriedName: data.marriedName,
                    phone1: removeNonNumeric(data.PhoneNumber),
                    dob: data.Birthday,
                    clientId: clientId,
                },
            };
            if (isUpdate) {
                dispatch(patchInsured({ body, id: personId }));
                setOpenModal(false);
            } else {
                dispatch(postInsured(body));
            }
        },
        [dispatch]
    );

    const editInsured = 
        (row:any, setIsMaskedDocument:any, setIsUpdate:any, setPersonId:any, setTitleInsured:any, setOpenModal:any, listIdentificationType:any, setDataFormInsurance:any, dataFormInsurance:any) => {
            setIsMaskedDocument(false);
            setIsUpdate(true);
            setPersonId(row.row.id);
            setTitleInsured(t("confirmation.editInsured"));
            const names = row.row.names.split(" ");
            const firstNameA = names[0] || "";
            const firstNameB = names[1] || "";
            const lastNames = row.row.lastNames.split(" ");
            const lastNameA = lastNames[0] || "";
            const lastNameB = lastNames[1] || "";
            setOpenModal(true);

            listIdentificationType.forEach((item: select) => {
                if (item.name === "Pasaporte" && item.id === row.row.idType) {
                    setIsMaskedDocument(true);
                }
            });

            setDataFormInsurance({
                ...dataFormInsurance,
                Id: row.row.id,
                IdType: row.row.idType,
                DocumentNumber: row.row.documentNumber,
                Name: firstNameA,
                SecondName: firstNameB,
                LastNameA: lastNameA,
                LastNameB: lastNameB,
                marriedName: row.row.marriedName,
                Birthday: newFormattedDateYYMMDD(row.row.dob),
                PhoneNumber: row.row.phone1,
                idTypeInsured: row.row.idTypeInsured,
            });
        }
    

    const searchInsurancePerson = useCallback(
        (value:any, handleSearch:any) => {
            handleSearch(value, true);
        },
        []
    );

    return useMemo(() => ({
        handleAddInsured,
        setInsuranceStorybook,
        handleSubmitInsured,
        editInsured,
        searchInsurancePerson,
    }), [
        handleAddInsured,
        setInsuranceStorybook,
        handleSubmitInsured,
        editInsured,
        searchInsurancePerson,
    ]);
};

export default useInsurance;
